import { FormApp } from "appdland-ui";
import CodeInputs from "../../components/CodeInputs";
import ButtonGB from "../../components/ButtonGB";
import { usePopUp } from "../../context/PopUp.context";
import { useState } from "react";
import useLoginService from "../../services/login.service";

interface VerifyCodeInt {
    user: string;
    onSuccess: () => void;
    onError?: () => void;
}

const VerifyCode = ({ user, onSuccess, onError }: VerifyCodeInt) => {
    const { forgotSecondCall } = useLoginService();
    const [code, setCode] = useState(0);
    const { setPopUp } = usePopUp();

    const handleValidateForm = () => {
        if (code === 0 || isNaN(code)) {
            setPopUp({
                title: 'Ups',
                content: <p>Parece que no has ingresado un código válido</p>
            });
            return false;
        }

        return true;
    }

    const verifyCode = () => {
        forgotSecondCall(user, code)
            .then(() => {
                onSuccess();
            })
            .catch((err) => {
                setCode(0);
                if (onError && err === "Se ha agotado el tiempo, por favor inicia el proceso nuevamente") {
                    onError();
                }
            })
    }

    return (
        <FormApp onSubmit={verifyCode} validateForm={handleValidateForm} className="forgot-form">
            <p>Hemos enviado un <b>código de seis dígitos</b> al número de WhatsApp asociado a tu cuenta, a continuación ingresa el código.</p>
            <CodeInputs
                setCode={setCode}
            />
            <ButtonGB
                title="Continuar"
            />
            <p className="forgot-button">Reenviar Código</p>
        </FormApp>
    )
}

export default VerifyCode;