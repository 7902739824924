import { ordersInt, useDataPackage } from "../../context/DataPackage.context";
import { useNavigate } from "react-router-dom";
import { formatDate, formatMoney, formatSeparate } from "../../functions/formats";
import { FaCircleCheck } from "react-icons/fa6";
import { FaClock } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { useEffect, useState } from "react";
import "./styles/orders.css";
import ButtonGB from "../../components/ButtonGB";

interface ordersGroupInt {
    [key: string]: ordersInt[];
}

const Orders = () => {

    const { orders } = useDataPackage();
    const navigate = useNavigate();
    const [ordersByDate, setOrdersByDate] = useState<ordersInt[][]>();

    useEffect(() => {
        const grouped: ordersGroupInt = {};
        if (orders) {
            orders.forEach(order => {
                const date = order.action_date.split(' ')[0];
                if (!grouped[date]) {
                    grouped[date] = [];
                }
                grouped[date].push(order);
            });
        }

        const result = Object.values(grouped);
        setOrdersByDate(result)

    }, [orders]);

    useEffect(() => {
        document.title = "Envíos | ColCambios";
    }, []);

    return (
        <div>
            <h2 className="section-title-gb">Mis Envíos</h2>
            <div className="orders-list-container">
                {
                    ordersByDate &&
                        ordersByDate.length > 0 ? ordersByDate.map((order, index) => (
                            <div key={index} className="orders-list-group">
                                <h4>{formatDate(new Date(order[0].action_date), false, true)}</h4>
                                {
                                    order.map(({ order_id, action_date, from_data, to_data, real_val_from, val_to, order_state }, index) => (
                                        <div key={index} onClick={() => navigate(`${order_id}`)} className="orders-list-box-container">
                                            <div className="orders-list-box-row">
                                                <div className="orders-list-state">
                                                    <h3>{order_id}</h3>
                                                    {
                                                        order_state === "cancelled" || order_state === "cancelled_by_client" || order_state === "cancelled_by_admin" ? (
                                                            <MdCancel color="red" size={window.innerWidth > 450 ? 20 : 13} />
                                                        ) : order_state === "completed" ? (
                                                            <FaCircleCheck color="green" size={window.innerWidth > 450 ? 20 : 13} />
                                                        ) : (
                                                            <FaClock size={window.innerWidth > 450 ? 20 : 13} />
                                                        )
                                                    }



                                                </div>
                                                <p className="orders-list-date">{formatDate(new Date(action_date))}</p>
                                            </div>
                                            <div className="orders-list-box-row orders-list-box-row-column">
                                                <p style={{ fontWeight: 300 }}>{formatSeparate(from_data.slice(0, -3))}</p>
                                                <p style={{ fontWeight: 300 }}>{formatSeparate(to_data.slice(0, -3))}</p>
                                            </div>
                                            <div className="orders-list-box-row orders-list-box-row-column">
                                                <p>${formatMoney(real_val_from, from_data === "PayPalUSD" ? 2 : 0)}</p>
                                                <p>${formatMoney(val_to, to_data === "PayPalUSD" ? 2 : 0)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )) : (
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                            <br />
                            <p style={{textAlign: "center", color: "gray"}}>No hay envíos para mostrar, pero puedes enviar ahora</p>
                            <br />
                            <br />
                            <ButtonGB onClick={() => navigate('/perfil/enviar')}>Enviar</ButtonGB>
                        </div>
                    )
                }





            </div>
        </div>
    )
}

export default Orders;