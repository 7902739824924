import axios from "axios";
import useServices from "./services.service";

const usePageService = () => {

    const { manageError } = useServices();

    const getChanges = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_GET_CHANGES || "");
            return data;
        } catch (error) {
            manageError(error);
        }
    }

    return {
        getChanges
    }
}

export default usePageService;