import { useEffect } from "react";
import Header from "../modules/Header";
import Inovate from "../modules/sections/Inovate";
import Beneficios from "../modules/sections/Beneficios";
import Footer from "../modules/Footer";
import Forgot from "../modules/Forgot/Forgot";


const Recover = () => {
    useEffect(() => {
        document.title = "Olvidé mi contraseña";
    }, []);
    return (
        <div>
            <Header />
            <Forgot />
            <Inovate />
            <Beneficios />
            <Footer />
        </div>
    )
}

export default Recover;