import { SelectApp, SelectAppProps } from "appdland-ui";

const SelectGB = (props: SelectAppProps) => {
    return (
        <SelectApp
            style={{
                // type: "bottom-line",
                // textAlign: "center",
                ...props.style
            }}
            optionsStyle={{
                // textAlign: "center"
                // textAlign: "center"
            }}
            {...props}
        />
    )
}

export default SelectGB;