import { FormApp, useFormApp } from "appdland-ui";
import ButtonGB from "../../components/ButtonGB";
import InputGB from "../../components/InputGB";
import PasswordValidator from "password-validator";
import useAuthService from "../../services/auth.service";
import TitleGB from "../../components/TitleGB";
import FormPass from "../Register/FormPass";
import "./styles/wpchange.css";


interface FormPass {
    password: string;
    newPassword: string;
    validPass: string;
}

const PassChange = () => {
    const { register, formValues, validateForm, setForm } = useFormApp<FormPass>();
    const { forgotChangePass } = useAuthService();

    const schema = new PasswordValidator();

    const handleValidate = () => {

        let validator = validateForm();
        const schema = new PasswordValidator();
        schema
            // .is().min(8)
            .has().lowercase()
            .has().digits(1)
            .not().spaces();

        if (formValues.newPassword !== formValues.validPass) {
            setForm("newPassword", formValues.newPassword, true, "Contraseña No Coincide");
            setForm("validPass", formValues.validPass, true, "Contraseña No Coincide");
            validator = false;
        }
        if (formValues.newPassword === schema) {
            setForm("newPassword", formValues.newPassword, true, "Contraseña No Cumple Lo Requerido");
            validator = false;
        }

        if (formValues.newPassword === formValues.password) {
            setForm('newPassword', formValues.newPassword, true, "Ingresa una diferente");
            validator = false;
        }

        return validator
    }

    return (
        <FormApp
            onSubmit={() => forgotChangePass(formValues.password, formValues.newPassword)}
            validateForm={handleValidate}
            className="change-wp-body"
        >
            <TitleGB>Cambiar Contraseña</TitleGB>
            <InputGB
                {...register("password", { min: 8, errorEvents: { onMinError: 'No parece tu contraseña' } })}
                placeholder="Contraseña Actual"
                type="password"
            />
            <br />
            <br />
            <p style={{ textAlign: 'center' }}><b>Ahora ingresa tu nueva contraseña</b></p>
            <FormPass
                password={{ ...register('newPassword', { min: 8 }) }}
                passwordValue={formValues.newPassword}
                confPassword={{
                    ...register('validPass', {
                        errorEvents: {
                            onRequiredError: "Contraseña no válida"
                        }
                    })
                }}
                confPasswordValue={formValues.validPass}
                schema={schema}
            />
            <ButtonGB
                title="Enviar Codigo"
            />
        </FormApp>

    )
}

export default PassChange;