import copy from 'clipboard-copy';
export const formatComas = (number: number) => {
    return Intl.NumberFormat('es-MX').format(number)
}

export const formatRevertComas = (number: string) => {
    return Number(number.replaceAll(",", ""));
}

/**
 * formato con separadores de miles usando comas
 * @param number numero a convertir
 * @param decimal cantidad de decimales (opcional)
 * @returns numero formateado a dinero
 */
export const formatMoney = (number: number, decimal: number = 2) => {
    return Intl.NumberFormat('es-MX', { minimumFractionDigits: decimal, maximumFractionDigits: 2 }).format(number)
}

export const formatDate = (date: number | Date, withTime: boolean = true, withYear: boolean = false) => {
    const dateObj = new Date(date);
    const formatedDate = new Date(dateObj.getTime() - (dateObj.getTimezoneOffset() * 60000));
    const day = formatedDate.toLocaleDateString('es-ES', {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
        year: withYear ? "numeric" : undefined
    });
    const [dayPart, timePart] = day.split(',');

    return `${dayPart.trim()} ${withTime ? `, ${timePart}` : ""}`;
}

export const formatUpperFirst = (text: string) => {
    return text.slice(0, 1).toUpperCase() + text.slice(1);
}

export const formatUpper = (text: string) => {
    return text.split('_')
        .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
}

/**
 * devuelve un numero entero en string, elimina cualquier caracter o puntuacion
 * @param text numero en string
 * @returns numero en string formateado
 */
export const formatInteger = (text: string) => {
    const cleanedText = text.replace(/[^\d]/g, '').replace(/^0+/, '');
    return String(cleanedText === '' ? "" : parseInt(cleanedText, 10));
}

/**
 * devuelve una cadena de texto libre de numeros y puntuaciones, ideal para formatear nombres
 * @param text cadena de texto
 * @returns cadena de texto formateada
 */
export const formatName = (text: string) => {
    return text.replace(/[^A-Za-zñáéíóúüÜÁÉÍÓÚÑñ\s]/g, '');
}

/**
 * Separa una cadena de texto en cada letra mayuscula encontrada
 * @param text texto con mayusculas
 * @return cadena de texto formateada
 */
export const formatSeparate = (text: string) => {
    const newText = text.replace(/([A-Z])/g, ' $1').trim();
    return newText === "Pay Pal" ? "PayPal" : newText === "Bancosde Colombia" ? "Bancos de Colombia" : newText
}

export const currencyTransformer = (currency: string) => {
    switch (currency) {
        case "COP":
            return "Pesos Colombianos"
        case "ARS":
            return "Pesos Argentinos"
        default:
            return "Dólares"
    }
}

export const handleCopy = async (text: string | number) => {
    await copy(String(text));
}

export const paypalCalculator = (value: number) => Number(((value + 0.3) / 0.946).toFixed(2));
export const paypalReceived = (value: number) => Number((value - (value * 0.054) - 0.3).toFixed(2));