import { accountInt, paymentInt } from '../../context/DataPackage.context';
import { currencyTransformer, formatMoney, handleCopy, paypalReceived } from '../../functions/formats';
import { BsCopy } from "react-icons/bs";
import { FaClock } from "react-icons/fa6";
import { useRef, useState } from "react";
import ButtonGB from "../../components/ButtonGB";
import { usePopUp } from "../../context/PopUp.context";
import NotReady from "../../components/NotReady";
import usePaymentsService from "../../services/payments.service";
import CantSend from "../../components/CantSend";
import PaymentTitle from './PaymentTitle';
import PaymentBody from './PaymentBody';
import Advertising from './Advertising';
import "./styles/payments.css";


interface PaymentsInt {
    payments_from: paymentInt[];
    payments_to: paymentInt[];
    benef: accountInt;
    order_id: string;
    order_state: string;
    mountInfo?: number;
}

const Payments = ({ payments_from, payments_to, benef, order_id, order_state, mountInfo }: PaymentsInt) => {

    return (
        <div className="payments-container">
            {
                order_state !== "cancelled" && order_state !== "cancelled_by_admin" && order_state !== "cancelled_by_client" && (
                    <div className='payments-container-side'>
                        {
                            payments_from.map((payment, index) => (
                                <PaymentBox
                                    key={index}
                                    title={
                                        <PaymentTitle
                                            state={payment.payment_state}
                                            currency={payment.currency}
                                            index={index}
                                            length={payments_from.length}
                                        />
                                    }
                                    body={<PaymentBody payment={payment} action="from" />}
                                />
                            ))
                        }
                    </div>
                )
            }
            {
                payments_to.length > 0 && <p className='payment-text-info payment-text-info-1'>Puedes recibir varios pagos. Verifica siempre los comprobantes</p>
            }
            {
                payments_to.length > 0 ? (
                    <div className='payments-container-side'>
                        <p className='payment-text-info payment-text-info-2'>Puedes recibir varios pagos. Verifica siempre los comprobantes</p>
                        {
                            payments_to.map((payment, index) => (
                                <PaymentBox
                                    key={index}
                                    title={
                                        <PaymentTitle
                                            state={payment.payment_state === "pending" ? "pending2" : payment.payment_state}
                                            currency={payment.currency}
                                            index={index}
                                            length={payments_to.length}
                                        />
                                    }
                                    body={<PaymentBody payment={payment} action="to" benef={benef} />}
                                />
                            ))
                        }
                        {
                            mountInfo !== undefined && (
                                <>
                                    {
                                        mountInfo > 0 && (
                                            <p style={{ marginBottom: '15px' }}><small>Restante por enviar ${formatMoney(benef.account_bank === "PayPal" ? paypalReceived(mountInfo) : mountInfo, 0)} {currencyTransformer(payments_to[0].currency)}</small></p>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                ) : order_state === "created" ? (
                    <Advertising acc={benef} order_id={order_id} />
                ) : null
            }
        </div>
    )
}

const PaymentBox = ({ title, body }: { title: React.ReactNode, body: React.ReactNode }) => (
    <div className='payments-box'>
        {title}
        {body}
    </div>
);

interface PendingPaymentInt {
    payInfo: paymentInt;
    decimals: 2 | 0;
}

const PendingPayment = ({ payInfo, decimals }: PendingPaymentInt) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null);
    const [cantSend, setCantSend] = useState(false);
    const [fileError, setFileError] = useState('');

    const { pay } = usePaymentsService();


    const handlePayment = async () => {
        if (!selectedFile) {
            alert('Selecciona un archivo antes de subirlo.');
            return;
        }

        await pay({
            file: selectedFile,
            payment_id: payInfo.payment_id
        });
    }

    const handleFileChange = (event: any) => {
        setFileError('');
        const file = event.target.files[0];

        if (file) {
            //verifico el máximo de tamaño
            if (file.size > 500000) {
                console.log('big')
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setFileError('Archivo muy grande');
                return;
            }
            setSelectedFile(file);


            // Previsualización del archivo
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            setSelectedFile(null);
            setPreviewUrl(null);
        }
    };

    const handleClick = () => {
        // Abre el explorador de archivos al hacer clic en el botón
        fileInputRef.current?.click();
    };

    return (
        <div className="payment-container">
            <div className="payments-row">
                <div>
                    <div className="payments-amount-cont">
                        <h3 className="payments-amount">${formatMoney(payInfo.amount, decimals)}</h3>
                        {
                            payInfo.order_id_from && (
                                <BsCopy cursor="pointer" onClick={() => handleCopy(Number(payInfo.amount))} size={15} />
                            )
                        }
                    </div>
                    <h3 className="payments-amount">{currencyTransformer(payInfo.currency)}</h3>
                </div>

                <FaClock size={20} />
            </div>
            {
                payInfo.account_id && cantSend === false && (
                    <div className="payments-column">
                        <p style={{ opacity: 0.6, userSelect: 'none', fontStyle: "italic" }}><small>Envía ${formatMoney(payInfo.amount, decimals)} a la siguiente cuenta:</small></p>
                    </div>

                )
            }


            {/* {
                payInfo.account_id ? (
                    <PayDetail payInfo={payInfo} />
                ) : (
                    <div className="payments-column">
                        <p><small>Pronto recibirás un pago por este monto</small></p >
                    </div >
                )
            } */}

            {
                cantSend ? (
                    <CantSend payment_id={payInfo.payment_id} setCantSend={setCantSend} />
                ) : payInfo.order_id_from ? (
                    <div className="payments-last">
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <p>{fileError}</p>
                        {
                            selectedFile ? (
                                <div>
                                    {
                                        previewUrl && selectedFile.type !== 'application/pdf' && (
                                            <div className="payment-image">
                                                <img
                                                    src={String(previewUrl)}
                                                    alt="Vista previa"
                                                    style={{ maxWidth: '100%', maxHeight: '150px' }}
                                                />
                                            </div>
                                        )
                                    }
                                    <p style={{ fontSize: "x-small", color: "gray", textAlign: "center" }}>{selectedFile.name}</p>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <ButtonGB
                                            onClick={handlePayment}
                                            title="Enviar Comprobante"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <ButtonGB
                                        onClick={handleClick}
                                        title="Subir Comprobante"
                                    />
                                </div>
                            )
                        }
                        <button className="payments-links-button" onClick={() => setCantSend(true)}><small>No puedo enviar a esta cuenta</small></button>
                    </div>
                ) : null
            }

        </div >
    )
}

const PaidPayment = ({ payInfo, decimals }: PendingPaymentInt) => {

    const { setPopUp } = usePopUp();
    const { getImage } = usePaymentsService();

    const handleImage = () => {
        getImage(payInfo.payment_id)
            .then(image => {
                setPopUp({
                    content: <Comprobante imageSrc={image} texto={`${payInfo.order_id_from || "ColCambios"}-${payInfo.payment_id}`} />,
                    title: 'Comprobante'
                });
            }).catch(() => window.location.reload());

    }

    return (
        <div className="payment-container">
            <div className="payments-row">
                <div>
                    <div className="payments-amount-cont">
                        <h3 className="payments-amount">${formatMoney(payInfo.amount, decimals)}</h3>
                        {
                            payInfo.order_id_from && (
                                <BsCopy cursor="pointer" onClick={() => handleCopy(Number(payInfo.amount))} size={15} />
                            )
                        }
                    </div>
                    <h3 className="payments-amount">{currencyTransformer(payInfo.currency)}</h3>
                </div>

                <FaClock size={20} />
            </div>

            <div className="payments-column">
                <p>Se ha enviado tu comprobante correctamente</p>
            </div>

            {
                !payInfo.order_id_from && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {/* <PayDetail payInfo={payInfo} /> */}
                    </div>
                )
            }

            <div className="payments-last">
                <div>
                    <ButtonGB
                        title="Ver comprobante"
                        onClick={handleImage}
                    />
                </div>
            </div>
        </div>
    )
}


// const CompletedPayment = ({ payInfo, decimals }: PendingPaymentInt) => {

//     const { setPopUp } = usePopUp();
//     const { getImage } = usePaymentsService();

//     const handleImage = () => {
//         getImage(payInfo.payment_id)
//             .then(image => {
//                 setPopUp({
//                     content: <Comprobante imageSrc={image} texto={`${payInfo.order_id_from || "ColCambios"}-${payInfo.payment_id}`} />,
//                     title: 'Comprobante'
//                 });
//             })

//     }

//     return (
//         <div className="payment-container">
//             <div className="payments-row">
//                 <div>
//                     <div className="payments-amount-cont">
//                         <h3 className="payments-amount">${formatMoney(payInfo.amount, decimals)}</h3>
//                     </div>
//                     <h3 className="payments-amount">{currencyTransformer(payInfo.currency)}</h3>
//                 </div>

//                 <FaCircleCheck size={20} color="green" />
//             </div>
//             {
//                 payInfo.account_id ? (
//                     <div className="payments-column">
//                         <small>Recibimos correctamente tu pago</small>
//                         <div style={{ display: "flex", justifyContent: "center" }}>
//                             {/* <PayDetail payInfo={payInfo} /> */}
//                         </div>
//                     </div>
//                 ) : (
//                     <div className="payments-column">
//                         <small>Has recibido este pago</small>
//                     </div>
//                 )
//             }
//             <div className="payments-last">
//                 <div>
//                     <ButtonGB
//                         title="Ver comprobante"
//                         onClick={handleImage}
//                     />
//                 </div>
//             </div>

//         </div>
//     )
// }

interface ComprobanteInt {
    texto: string;
    imageSrc: any;
}

const Comprobante = ({ texto, imageSrc }: ComprobanteInt) => {

    const [imageName, setImageName] = useState(texto);

    return (
        <div className="payments-image-container">
            {
                imageSrc ? (
                    <>
                        <div className="payments-image">
                            <img
                                src={imageSrc}
                                alt={`comprobante ${texto}`}

                            />

                        </div>
                        <a href={imageSrc} download={imageName}>
                            <p>Descargar Comprobante {imageName}</p>
                        </a>
                    </>

                ) : (
                    <NotReady />
                )
            }
        </div>
    )
}

export default Payments;