import { useEffect, useState } from "react";
import Footer from "../modules/Footer";
import Header from "../modules/Header";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { preguntasData } from "../data/preguntas";
import "./styles/prf.css";

const Preguntas = () => {

    const [state, setState] = useState(0);

    useEffect(() => { document.title = "Preguntas Frecuentes" }, []);

    return (
        <>
            <Header />
            <div className="colcambios-prf-container">
                <div className="colcambios-prf-title">
                    <h2>Preguntas Frecuentes</h2>
                </div>
                <Slide state={state} setState={setState} />
                {
                    preguntasData[state].groupInfo.map((info, index) => (
                        <Question key={index} title={info.title} info={info.info} />
                    ))
                }
            </div>
            <br />
            <br />
            <Footer />
        </>
    )
}

const Slide = ({ state, setState }: { state: number, setState: (param: number) => void }) => {

    return (
        <div className="colcambios-prf-slide-box">
            <div onClick={() => setState((state - 1) < 0 ? (preguntasData.length - 1) : (state - 1))} className="colcambios-prf-ghost">
                <IoIosArrowBack size={20} />
                <p>{preguntasData[(state - 1) < 0 ? (preguntasData.length - 1) : (state - 1)].titleGroup}</p>
            </div>
            <p className="colcambios-prf-active">{preguntasData[state].titleGroup}</p>
            <div onClick={() => setState((state + 1) > (preguntasData.length - 1) ? 0 : (state + 1))} className="colcambios-prf-ghost">
                <p>{preguntasData[(state + 1) > (preguntasData.length - 1) ? 0 : (state + 1)].titleGroup}</p>
                <IoIosArrowForward size={20} />
            </div>
        </div>
    )
}

const Question = ({ title, info }: { title: string, info: string | React.ReactNode }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`colcambios-question-box ${open && "colcambios-question-box-active"}`}>
            <div onClick={() => setOpen(!open)} className={`colcambios-question-title ${open && "colcambios-prf-active-active"}`}>
                <p>{title}</p>
                <p className={`colcambios-question-icon ${open && "colcambios-question-icon-active"}`}>{open ? "-" : "+"}</p>
            </div>
            {
                open &&
                <div className="colcambios-question-info">
                    {
                        typeof info === "string" ? <p>{info}</p> : info
                    }
                </div>
            }
        </div>
    )
}

export default Preguntas;