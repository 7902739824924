import { usePopUp } from "../../context/PopUp.context";
import usePaymentsService from "../../services/payments.service";
import NotReady from "../../components/NotReady";
import ButtonGB from "../../components/ButtonGB";
import "./styles/viewimage.css";

interface ViewImageInt {
    payment_id: number;
    light?: boolean;
}

const ViewImage = ({ payment_id, light = false }: ViewImageInt) => {

    const { setPopUp } = usePopUp();
    const { getImage } = usePaymentsService();

    const handleImage = () => {
        getImage(payment_id)
            .then(image => {
                setPopUp({
                    content: <Comprobante imageSrc={image} texto={`ColCambios-${payment_id}`} />,
                    title: 'Comprobante'
                });
            })

    }

    return (
        <ButtonGB
            title="Ver comprobante"
            onClick={handleImage}
            style={{
                type: light ? 'light' : 'solid',
                textColor: light ? '#004E77' : 'white'
            }}
        />
    )
}

interface ComprobanteInt {
    texto: string;
    imageSrc: any;
}

const Comprobante = ({ texto, imageSrc }: ComprobanteInt) => {

    return (
        <div className="payments-image-container">
            {
                imageSrc ? (
                    <>
                        <div className="payments-image">
                            <img
                                src={imageSrc}
                                alt={`comprobante ${texto}`}

                            />
                        </div>
                        <a href={imageSrc} download={texto}>
                            <p>Descargar Comprobante {texto}</p>
                        </a>
                    </>

                ) : (
                    <NotReady />
                )
            }
        </div>
    )
}

export default ViewImage