import { FormApp } from "appdland-ui";
import CodeInputs from "../../components/CodeInputs";
import ButtonGB from "../../components/ButtonGB";
import useAuthService from "../../services/auth.service";
import { useState } from "react";
import "./styles/wpchange.css";
import TitleGB from "../../components/TitleGB";

const PassChangeCode = () => {

    const { validCode } = useAuthService();
    const [code, setCode] = useState<number>(Number);

    return (
        <FormApp
            onSubmit={() => validCode(code)}
            className="change-wp-body"
        >
            <TitleGB>Cambiar Contraseña</TitleGB>
            <p style={{ textAlign: 'center' }}>Antes de continuar verificaremos que eres tú para evitar inconvenientes de seguridad. Por favor ingresa el código de seis digitos que te enviamos por WhatsApp</p>
            <br />
            <CodeInputs
                setCode={setCode}
            />
            <ButtonGB
                title="Validar Codigo"
            />
        </FormApp>
    )
}

export default PassChangeCode;