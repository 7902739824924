import { IoMdArrowDropdown } from "react-icons/io";
import "./styles/selectgb.css";
import { useEffect, useRef, useState } from "react";

interface optionsInt {
    label: string;
    value: string | number;
    extra?: string | React.ReactElement;
}

interface SelectGBInt {
    value: string | number;
    onChange: (param: string | number) => void;
    options: optionsInt[];
    placeHolder?: string;
    disabled?: boolean;
    errorState?: boolean;
    variant?: "outline" | "single";
    labelStyle?: React.CSSProperties;
}
const SelectGBdesc = ({ value, onChange, placeHolder = "Selecciona", options, disabled = false, errorState, variant = "outline", labelStyle }: SelectGBInt) => {

    const [showList, setShowList] = useState(false);
    const [activeLabel, setActiveLabel] = useState("");
    const selectRef = useRef<HTMLDivElement>(null);

    const handleChange = (value: string | number, label: string) => {
        onChange(value);
        setActiveLabel(label);
        setShowList(false);
    }

    useEffect(() => {
        if (value !== "") {
            const found = options.find(op => op.value === value);
            if (found) {
                setActiveLabel(found.label);
            } else {
                setActiveLabel("");
            }
        }
        const action = ({ target }: any) => {
            if (!selectRef.current?.contains(target)) {
                setShowList(false);
            }
        }

        document.addEventListener("click", action, true);
        return () => document.removeEventListener("click", action, true);
    }, [value, options]);


    const handleClick = () => {
        if (disabled === false) {
            if (showList === false) {
                selectRef.current?.scrollIntoView({ block: "center", behavior: "smooth" })
            }
            setShowList(!showList);
        }
    }

    return (
        <div className="selectGB-box" ref={selectRef}>
            <div className="selectGB" onClick={handleClick} style={{
                border: variant === "outline" ? `1px solid ${errorState ? "red" : "rgb(0, 78, 119)"}` : ""
            }}>
                <p
                    style={{
                        ...labelStyle,
                        color: labelStyle?.color ? labelStyle.color : activeLabel !== "" ? "black" : "gray",
                        fontSize: labelStyle?.fontSize ? labelStyle.fontSize : activeLabel !== "" ? "medium" : "small"
                    }}
                >
                    {activeLabel !== "" ? activeLabel : placeHolder}
                </p>
                <div className="selectGB-icon">
                    <IoMdArrowDropdown size={16} color="gray" />
                </div>
            </div>

            {
                showList && (
                    <div className="selectGB-list">
                        <p className="selectGB-list-title">{placeHolder}</p>
                        {
                            options.map(({ label, value, extra }, index) => (
                                <div className="selectGB-list-op" key={index} onClick={() => handleChange(value, label)}>
                                    {
                                        extra && (
                                            <div className="selectGB-list-op-left">
                                                {
                                                    typeof (extra) === "string" ? (
                                                        <p>{extra}</p>
                                                    ) : (
                                                        extra
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                    <p className="selectGB-list-op-label">{label}</p>
                                </div>
                            ))
                        }
                    </div>
                )
            }

        </div>
    )
}

export default SelectGBdesc;