import colcambiosWhite from "../assets/images/logo-white.png";
import useNavigator from "../router/useNavigator";
import { HiUser } from "react-icons/hi2";
import BurgerMenu from "../components/BurgerMenu";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../context/TokenContext";
import { AiFillHome } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { HiCurrencyDollar } from "react-icons/hi2";
import { FaCircleQuestion } from "react-icons/fa6";
import "./styles/Header.css";

const Header = () => {
    const [navState, setNavState] = useState("");
    const navigate = useNavigate();
    const { token } = useAuth();
    const headerRef = useRef<HTMLHeadingElement>(null);
    const [status, setStatus] = useState(false);

    const togleMenu = () => {
        if (navState === "") {
            document.body.style.overflowY = 'hidden';
            setNavState("nav-active");
        } else {
            document.body.style.overflowY = 'auto';
            setNavState("");
        }
    }

    useEffect(() => {
        headerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        const outside = (e: any) => {
            if (headerRef.current && !headerRef.current.contains(e.target)) {
                document.body.style.overflowY = 'auto';
                setNavState("");
                setStatus(false);
            }
        }

        document.addEventListener('click', outside);

        return () => {
            document.removeEventListener('click', outside);
        }
    }, []);

    return (
        <header ref={headerRef}>
            <div className="burger-menu-container">
                <BurgerMenu
                    handleAction={togleMenu}
                    status={status}
                    setStatus={setStatus}
                />
            </div>
            <nav className={navState}>
                <ul>
                    <Link
                        url="/"
                        icon={<AiFillHome />}
                        label="Inicio"
                    />
                    <Link
                        url={!token ? '/ingresar' : '/perfil/enviar'}
                        icon={<HiUser />}
                        label="Perfil"
                    />
                    <Link
                        url="/precios"
                        icon={<HiCurrencyDollar />}
                        label="Precios"
                    />
                    <Link
                        url="/preguntas"
                        icon={<FaCircleQuestion />}
                        label="Preguntas"
                    />
                </ul>
            </nav>
            <img
                src={colcambiosWhite}
                onClick={() => navigate("/")}
                alt="logo blanco de colcambios"
            />
        </header>
    )
}

interface LinkInt {
    url: string;
    icon: React.ReactNode;
    label: string
}

const Link = ({ url, icon, label }: LinkInt) => {
    const { nav, linkActive } = useNavigator(url);

    return (
        <div
            onClick={() => nav(url)}
            className="nav-option-normal"
            style={{ opacity: linkActive ? 1 : 0.6}}
        >
            <div
                className={`nav-option-decoration ${linkActive && 'nav-option-active'}`}
            />
            <div className="nav-option-icon">
                {icon}
            </div>
            <p>{label}</p>
        </div>
    )
}

export default Header;