import Changer from "../components/Changer";
import useSend from "../custom/useSend";
import DestForm from "./DestForm";
import DestDetail from "./DestDetail";
import NotReady from "../components/NotReady";
import SelectGBdesc from "../components/SelectGBdesc";
import { useRef } from "react";
import "./styles/send.css";

const Send = () => {

    const {
        beneff,
        innerBenef,
        setInnerBenef,
        handleSendDest,
        selectBlocker,
        toOp
    } = useSend();

    const formRef = useRef<HTMLDivElement>(null);

    return (
        <div>
            <h2 className="section-title-gb">Enviar</h2>
            <div className="send-screen-container">
                <div className="send-screen-changer">
                    <Changer />
                </div>
                <div className="send-screen-forms">
                    {
                        !selectBlocker ? (
                            <div className="send-main-box">
                                <h3>¿A quién le enviarás hoy?</h3>
                                <div className="send-main-select">
                                    <SelectGBdesc
                                        options={beneff}
                                        value={innerBenef}
                                        onChange={(val) => { setInnerBenef(Number(val)); setTimeout(() => formRef.current?.scrollIntoView({ block: "start", behavior: "smooth" }), 100) }}
                                        variant="outline"
                                        placeHolder="Elige quien recibe"
                                        disabled={selectBlocker}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div style={{ margin: '50px 0', opacity: 0.3, userSelect: 'none' }}>
                                <p><small>¿Cuánto vas a enviar hoy?</small></p>
                                <NotReady />
                            </div>
                        )
                    }
                    <div ref={formRef}>
                        {
                            !selectBlocker && (
                                innerBenef === 1 ? (
                                    <DestForm key={toOp} />
                                ) : innerBenef > 1 ? (
                                    <DestDetail innerBenef={innerBenef} send={handleSendDest} />
                                ) : (
                                    <NotReady />
                                )
                            )

                        }
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>

    )
}
export default Send;