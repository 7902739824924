import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

interface LiStateInt {
    [key: string]: boolean;
}

const useNavigator = (url: string) => {
    const navigate = useNavigate();
    const [linkActive, setLinkActive] = useState<boolean>(false);
    const { pathname } = useLocation();

    const formatUrl = (url: string) => '/' + url.split('/')[1];

    useEffect(() => {
        if (formatUrl(pathname) === formatUrl(url)) {
            setLinkActive(true);
        }
    }, [pathname]);

    const nav = (route: string) => {
        navigate(route);
    }

    return {
        nav,
        linkActive
    }
}

export default useNavigator;