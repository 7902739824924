import { useNavigate } from "react-router-dom";
import signUpIMG from "../../assets/images/signup2.svg";
import Backgrounds from "../../components/Backgrounds";
import "./styles/inovate.css";

interface InovateInt {
    bgColor?: "white" | "blue";
}

const Inovate = ({ bgColor = "white" }: InovateInt) => {
    const navigate = useNavigate();
    const handleRegister = () => {
        navigate("/registrarse");
    }
    const handleLogin = () => {
        navigate("/ingresar")
    }
    return (
        <Backgrounds color="white" title='Innovamos la Forma de Enviar'>
            <div className="innovate-content">
                <div className="innovate-sub-content">
                    <div className="innovate-sub-content-side">
                        <div className="innovate-decoration-circle">
                            <img
                                alt="imagen decorativa"
                                src={signUpIMG}
                            />
                        </div>
                    </div>
                    <div className="innovate-sub-content-side">
                        <p>Una experiencia única e innovadora para realizar tus envíos</p>
                        <li>Obtén ofertas exclusivas</li>
                        <li>Administra tus destinatarios</li>
                        <li>Gestiona tus envíos</li>
                    </div>
                </div>
                <p className="innovate-login" onClick={handleLogin}>¿Ya estás registrado?</p>
            </div>

        </Backgrounds>
    )
}

export default Inovate;