import "./styles/notready.css";

const NotReady = () => {

    return (
        <div className="not-ready-normal-container">
            <p>...</p>
        </div>
    )
}

export default NotReady;