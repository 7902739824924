import { ButtonApp, ButtonAppProps } from "appdland-ui";

const ButtonGB = (props: ButtonAppProps) => {

    return (
        <ButtonApp 
            {...props}
            style={{
                backgroundColor: "rgba(0, 78, 119, 1)",
                textColor: "white",
                ...props.style
            }}
        />
    )
}

export default ButtonGB;