import { FormApp } from "appdland-ui";
import CodeInputs from "../../components/CodeInputs";
import ButtonGB from "../../components/ButtonGB";
import { useState } from "react";
import useAuthService from "../../services/auth.service";
import TitleGB from "../../components/TitleGB";
import "./styles/wpchange.css";


const WpChangeCode = () => {

    const { validCodeWp } = useAuthService();
    const [code, setCode] = useState<number>(Number);

    return (
        <FormApp
            onSubmit={() => validCodeWp(code)}
            className="change-wp-body"
        >
            <TitleGB>Cambiar WhatsApp</TitleGB>
            <p style={{ textAlign: 'center' }}>Antes de continuar verificaremos tu nuevo número de WhatsApp, por favor ingresa el código de seis digitos</p>
            <br />
            <CodeInputs
                setCode={setCode}
            />
            <ButtonGB
                title="Validar Codigo"
            />
        </FormApp>
    )
}

export default WpChangeCode;