import { useEffect } from "react";
import adorno from "../assets/images/adorno.svg";
import './styles/loading.css';

export const Loading = () => {

    useEffect(() => {
        document.body.style.overflowY = "none";
        document.body.scrollIntoView({block: "start", behavior: "instant"})
    }, []);

    return (
        <div className='loading-screen'>
            <p className='loading-message'>Cargando ...</p>
            <img
                src={adorno}
                alt='adorno en pantalla de carga'
                className='adorno-2'
            />
            <div className="loading-circle">
                <div className="lds-roller">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        </div>
    );
};
