import { useRef, useState } from "react";
import usePaymentsService from "../../services/payments.service";
import ButtonGB from "../../components/ButtonGB";
import "./styles/uploadimage.css";

const UploadImage = ({ payment_id }: { payment_id: number }) => {

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [previewUrl, setPreviewUrl] = useState<string | ArrayBuffer | null>(null);
    const [fileError, setFileError] = useState('');

    const { pay } = usePaymentsService();


    const handlePayment = async () => {
        if (!selectedFile) {
            alert('Selecciona un archivo antes de subirlo.');
            return;
        }

        await pay({
            file: selectedFile,
            payment_id
        });
    }

    const handleFileChange = (event: any) => {
        setFileError('');
        const file = event.target.files[0];

        if (file) {
            //verifico el máximo de tamaño
            if (file.size > 5000000) {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setFileError('La imagen seleccionada excede el límite de peso*');
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(file);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                const MAX_WIDTH = 700;//
                const scaleSize = Math.min(MAX_WIDTH / img.width, 1);
                canvas.width = img.width * scaleSize;
                canvas.height = img.height * scaleSize;

                ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);

                //export
                const dataUrl = canvas.toDataURL('image/png', 0.7); //quality
                

                const blob = dataURLtoBlob(dataUrl);
                const newFile = new File([blob], file.name, {type: 'image/png'});
                setSelectedFile(newFile);
            }
            // setSelectedFile(file);


            // Previsualización del archivo
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            setSelectedFile(null);
            setPreviewUrl(null);
        }
    };

    const dataURLtoBlob = (data: string) => {
        const byteString = atob(data.split(',')[1]);
        const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
    }

    const handleClick = () => {
        // Abre el explorador de archivos al hacer clic en el botón
        fileInputRef.current?.click();
    };

    return (
        <div className="uploadimage-container">
            <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {
                fileError.length > 0 && <p className="uploadimage-error">{fileError}</p>
            }
            {
                selectedFile ? (
                    <div>
                        {
                            previewUrl && selectedFile.type !== 'application/pdf' && (
                                <div className="uploadimage-preview">
                                    <img
                                        src={String(previewUrl)}
                                        alt="Vista previa"
                                        style={{ maxWidth: '100%', maxHeight: '150px' }}
                                        onClick={handleClick}
                                    />
                                </div>
                            )
                        }
                        <p style={{ fontSize: "x-small", color: "gray", textAlign: "center" }}>{selectedFile.name}</p>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <ButtonGB
                                onClick={handlePayment}
                                title="Enviar Comprobante"
                            />
                        </div>
                    </div>
                ) : (
                    <div>
                        <ButtonGB
                            onClick={handleClick}
                            title="Subir Comprobante"
                        />
                    </div>
                )
            }
        </div>
    )
}

export default UploadImage;