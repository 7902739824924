import { useEffect, useState } from "react";
import TitleGB from "../../components/TitleGB";
import { useNavigate } from "react-router-dom";
import useOffersService from "../../services/offers.service";
import { OfferInt, useDataPackage } from "../../context/DataPackage.context";
import SelectGB from "../../components/SelectGB";
import { formatSeparate, formatMoney } from '../../functions/formats';
import { GiSevenPointedStar } from "react-icons/gi";
import ImageOption from "../../components/ImageOption";
import "./styles/offers.css";

const Offers = () => {

    const navigate = useNavigate();
    const { getOffers } = useOffersService();
    const { offers } = useDataPackage();
    const [filter, setFilter] = useState('all');
    const [filteredOffers, setFilteredOffers] = useState<OfferInt[]>();

    useEffect(getOffers, []);

    useEffect(() => {
        if (filter === 'all') {
            setFilteredOffers(offers);
        } else {
            if (offers) {
                const found = offers.filter(offer => offer.from_data === filter);
                setFilteredOffers(found);
            } else {
                setFilteredOffers([]);
            }
        }
    }, [filter, offers]);

    return (
        <div className="offers-body">
            <TitleGB>Ofertas</TitleGB>
            <p style={{ textAlign: 'center' }}>Aprovecha nuestras ofertas y obtén descuentos increíbles en tus envíos</p>
            <div className="offers-filter">
                <SelectGB
                    value={filter}
                    onChange={setFilter}
                    options={[
                        { value: 'all', label: 'Todas' },
                        { value: 'TransferenciaArgentinaARS', label: 'Transferencia Argentina' },
                        { value: 'NequiCOP', label: 'Nequi' },
                        { value: 'DaviplataCOP', label: 'Daviplata' },
                        { value: 'BancolombiaCOP', label: 'Bancolombia' },
                        { value: 'PayPalUSD', label: 'PayPal' }
                    ]}
                    placeholder="Filtrar"
                    style={{
                        showPlaceHolderOnFocus: false,
                    }}
                    optionsStyle={{
                        showPlaceholderOnList: true
                    }}
                />
            </div>
            {
                filteredOffers && filteredOffers.length > 0 ? (
                    <>
                        <p style={{ textAlign: 'center', fontStyle: 'italic' }}><small>La opción de cambio que se muestra, corresponde al banco o billetera desde donde realizarás el pago, el monto tachado corresponde al valor que enviarías sin el descuento y el valor remarcado es el valor que enviarás con descuento</small></p>
                        <br />
                        {
                            filteredOffers.map((offer, index) => (
                                <div key={index} className="offers-offer" onClick={() => navigate(`/perfil/enviar-oferta/${offer.id}`)}>
                                    <div>
                                        <ImageOption option={offer.from_data} />
                                        <p style={{ marginLeft: '10px' }}>{formatSeparate(offer.from_data.slice(0, -3))}</p>
                                    </div>
                                    <div>
                                        <p>${formatMoney(offer.val_from)}</p>
                                        <p>${formatMoney(offer.real_val_from)}</p>
                                    </div>
                                    <Desc value={offer.offer_desc} />
                                </div>
                            ))
                        }
                    </>
                )
                    : <p style={{ fontStyle: 'italic', fontSize: 'small', textAlign: 'center' }}>En este momento no tenemos ofertas disponibles</p>
            }
            <br />
            <br />
            <br />
            <br />
        </div>
    )
}

const Desc = ({ value }: { value: number }) => (
    <div className={`offers-desc-box ${Number(value) <= 1 ? 'offers-desc-box-green-1' : Number(value) <= 2 ? 'offers-desc-box-green-2' : 'offers-desc-box-green-3'}`}>
        <GiSevenPointedStar size={20} />
    </div>
)

export default Offers;