import ButtonGB from "../../components/ButtonGB";
import { accountInt, paymentInt } from "../../context/DataPackage.context";
import { currencies } from "../../data/currencies";
import { currencyTransformer, formatMoney, paypalCalculator, paypalReceived } from '../../functions/formats';
import usePaymentsService from "../../services/payments.service";
import PayDetail from "./PayDetail";
import UploadImage from "./UploadImage";
import ViewImage from "./ViewImage";
import "./styles/paymentBody.css";

const PaymentBody = ({ payment, action, benef }: { payment: paymentInt, action: 'from' | 'to', benef?: accountInt }) => (
    <>
        <div className="payment-body-box">
            <PaymentMoney
                action={action}
                amount={payment.amount}
                currency={payment.currency}
                state={payment.payment_state}
                payment_id={payment.payment_id}
                paypal={action === "to" && benef && benef.account_bank === "PayPal" ? true : false}
            />
            {
                payment.payment_state === "pending" ? (
                    action === "from" ? (
                        <PayDetail
                            type={payment.account_type}
                            bank={payment.account_bank}
                            num={payment.account_num}
                            client_name={payment.client_name}
                            doc_type={payment.doc_type}
                            doc_num={payment.doc_num}
                        />
                    ) : (
                        benef && <PayDetail
                            type={benef.account_type}
                            bank={benef.account_bank}
                            num={benef.account_num}
                            client_name={benef.client_name}
                            doc_type={benef.doc_type}
                            doc_num={benef.doc_num}
                            allowCopy={false}
                        />
                    )
                ) : payment.payment_state === "paid" ? (
                    action === "from" ? (
                        <PayDetail
                            bank={payment.account_bank}
                            client_name={payment.client_name}
                            allowCopy={false}
                        />
                    ) : (
                        <div className="payment-view-image">
                            <ViewImage payment_id={payment.payment_id} />
                        </div>
                    )
                ) : payment.payment_state === "validated" ? (
                    <div className="payment-view-image">
                        <ViewImage payment_id={payment.payment_id} light />
                    </div>
                ) : payment.payment_state === "completed" ? (
                    action === "from" ? (
                        <PayDetail
                            bank={payment.account_bank}
                            client_name={payment.client_name}
                            allowCopy={false}
                        />
                    ) : (
                        <div className="payment-view-image">
                            <ViewImage payment_id={payment.payment_id} />
                        </div>
                    )
                ) : (
                    <div>cancelado</div>
                )
            }
        </div>
        {
            (payment.payment_state === "validated" || payment.payment_state === "paid") && action === "to" && (
                <PaymentReceived paymentId={payment.payment_id} paymentInfo={`$${formatMoney(payment.amount)} ${currencyTransformer(payment.currency)}`} style={payment.payment_state === "validated" ? 'solid' : "light"} />
            )
        }
    </>
);

const PaymentMoney = ({ currency, amount, state, action, payment_id, paypal = false }: { currency: string, amount: number, state: string, action: 'from' | 'to', payment_id: number, paypal?: boolean }) => (
    <div className="payment-money-box">
        <p><b>${formatMoney(paypal ? paypalReceived(Number(amount)) : Number(amount), currency === "USD" ? 2 : 0)}</b></p>
        <p style={{ color: 'gray' }}><small>{currencies[currency]}</small></p>
        {
            state === "pending" && action === "from" && <UploadImage payment_id={payment_id} />
        }
    </div>
);

const PaymentReceived = ({ paymentId, paymentInfo, style = 'light' }: { paymentId: number, paymentInfo: string, style?: 'solid' | 'light' }) => {

    const { confirmPay } = usePaymentsService();

    return (
        <div className="payment-received">
            {
                style === "light" && <p>Si recibiste este pago por favor clickea</p>
            }
            <ButtonGB
                title="Recibí el Pago"
                style={{ type: style, textColor: style === "light" ? "#004E77" : "white" }}
                onClick={() => confirmPay(String(paymentId))}
                validateSubmit={{
                    title: '¿Recibiste el pago?',
                    text: style === "solid" ? `Confirma que recibiste este pago por ${paymentInfo}.` : `Confirma que recibiste este pago por ${paymentInfo}. De lo contrario por favor no lo confirmes para evitar demoras en tu envío.`
                }}
            />
        </div>
    )
}

export default PaymentBody;