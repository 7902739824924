import React, { useState, createContext, useEffect, useContext } from 'react';

interface AuthContextInt {
    token: string | undefined | null;
    setToken: (token: string | undefined | null) => void;
}

const AuthContext = createContext<AuthContextInt | null>(null);

interface AuthProviderProps {
    children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [token, setToken] = useState<string | undefined | null>(null);

    useEffect(() => {
        const storage = localStorage.getItem("__auth__colcambios__");
        if (storage) {
            setToken(storage);
        }
    }, []);

    useEffect(() => {
        if (!localStorage.getItem("__auth__colcambios__") && token) {
            localStorage.setItem('__auth__colcambios__', token);
        }
        if (token === undefined){
            localStorage.removeItem("__auth__colcambios__");
        }
    }, [token]);

    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = (): AuthContextInt => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext debe ser utilizado dentro de AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
