import { accountInt } from "../../context/DataPackage.context";
import { formatSeparate } from "../../functions/formats";
import PayDetail from "./PayDetail";
import { IoWarningOutline } from "react-icons/io5";
import useOrdersService from "../../services/orders.service";
import ButtonGB from "../../components/ButtonGB";
import './styles/advertising.css';

const Advertising = ({ acc, order_id }: { acc: accountInt, order_id: string }) => {
    const { cancelOrder } = useOrdersService();

    return (
        <div className="advertising-box">
            <div className="advertising-message">
                <IoWarningOutline size={22} color="red" />
                <p>Se enviará a {formatSeparate(acc.account_bank)} una vez recibido el pago completo.</p>
            </div>
            <div className="advertising-message advertising-message-full">
                <PayDetail
                    client_name={acc.client_name}
                    num={acc.account_num}
                    doc_type={acc.account_bank !== "PayPal" ? acc.doc_type : undefined}
                    doc_num={acc.doc_num}
                    bank={acc.account_bank}
                    type={acc.account_type}
                    allowCopy={false}
                    alwaysWhite
                />
                <br />
                <br />
                <br />
                <ButtonGB
                    actionStyle="cancel"
                    style={{ type: "light" }}
                    title="Cancelar Envío"
                    validateSubmit={{
                        title: "Cancelar Envío",
                        text: "¿Deseas cancelar este envío? esto quiere decir que ya no realizarás el pago, si ya realizaste algún pago por favor no cancelar."
                    }}
                    onClick={() => cancelOrder(order_id)}
                />
            </div>
        </div>
    )
}

export default Advertising;