import axios from "axios";
import useServices from "./services.service";
import { useDataPackage } from "../context/DataPackage.context";

const useOffersService = () => {

    const { getGeneral } = useServices();
    const { offers, setOffers } = useDataPackage();

    const getOffers = () => {
        getGeneral(offers, setOffers, 'offer-get');
    }

    return {
        getOffers
    }
}

export default useOffersService;