interface accType {
    value: string;
    label: string;
    extra?: string;
}

interface accTypes {
    [key: string]: accType[];
}

export const accTypes: accTypes = {
    COL: [
        { extra: "*", value: "ahorros", label: "Ahorros" },
        { extra: "*", value: "corriente", label: "Corriente" }
    ],
    ARG: [
        { extra: "*", value: "cvu", label: "CVU" },
        { extra: "*", value: "cbu", label: "CBU" },
        { extra: "*", value: "alias", label: "Alias" }
    ],
    USA: [
        { value: "email", label: "Email" }
    ]
}