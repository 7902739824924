import { createContext, useContext, useState } from "react";
import { Loading } from "../components/Loading";

interface ContextLoadingInt {
    loading: boolean;
    setLoading: (param: boolean) => void;
}

const LoadingConetxt = createContext<ContextLoadingInt | null>(null);


interface LoadingProps {
    children: React.ReactNode;
}

const LoadingProvider: React.FC<LoadingProps> = ({ children }) => {

    const [loading, setInnerLoading] = useState(false);

    const setLoading = (op: boolean) => {
        setInnerLoading(op);
    }

    return (
        <LoadingConetxt.Provider value={
            {
                loading,
                setLoading,
            }
        }>
            {
                loading === true && (
                    <Loading />
                ) 
            }
            {children}
        </LoadingConetxt.Provider>
    );
}


const useLoading = (): ContextLoadingInt => {
    const context = useContext(LoadingConetxt);
    if (!context) {
        throw new Error('useLoading debe ser utilizado dentro de LoadingProvider');
    }
    return context;
};

export { LoadingProvider, useLoading };