import { ChangeEvent, useEffect, useRef, useState } from "react";

import "./styles/codeinputs.css";

interface CodeInputs {
    setCode: (param: number) => void;

}

type VerificationCode = {
    [key: number]: string;
};

type VerificationCodeError = {
    [key: number]: boolean;
};

const CodeInputs = ({ setCode }: CodeInputs) => {

    const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
    const [verificationCode, setVerificationCode] = useState<VerificationCode>({});
    const [pasted, setPasted] = useState();

    const [verificationCodeError, setVerificationCodeError] = useState<VerificationCodeError>({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false
    });


    // useEffect(() => {

    //     if (validator) {
    //         for (let index = 0; index < 6; index++) {
    //             if (verificationCode[index] === "" || !verificationCode[index]) {
    //                 setVerificationCodeError((prev) => ({
    //                     ...prev,
    //                     [index]: true
    //                 }));
    //             }
    //         }
    //         setValidator(false);
    //     }

    // }, [validator]);

    useEffect(() => {
        let tempCode = '';
        for (let index = 0; index < 6; index++) {
            const element = verificationCode[index];
            tempCode += element;
        }
        setCode(Number(tempCode));
    }, [verificationCode]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, index: number): void => {
        const { value, name } = event.target;
        setVerificationCodeError((prev) => ({
            ...prev,
            [index]: false
        }));
        if (value.length > 1) {
            let fullVals: { [key: number]: string } = {};
            value.slice(0, 6).split('').map(each => {
                if (index < 6) {
                    fullVals[index] = each;

                }
                index++
            });
            setVerificationCode(fullVals);
            return;
        } else {
            setVerificationCode({
                ...verificationCode,
                [name]: value,
            });
        }

        // Si hay un valor en el campo actual, enfocar el siguiente campo
        if (value && event.target.nextSibling) {
            const nextInput = index === 2
                ? inputRefs.current[3] as HTMLInputElement
                : event.target.nextSibling as HTMLInputElement;
            nextInput.focus();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
        // Si se presiona la tecla de retroceso (Backspace) y el campo actual está vacío,
        // enfocar el campo anterior.

        if (event.key === 'Backspace' && !event.currentTarget.value && event.currentTarget.previousSibling) {
            const prevInput = index === 3
                ? inputRefs.current[2] as HTMLInputElement
                : event.currentTarget.previousSibling as HTMLInputElement;
            prevInput.focus();
        }
    };

    const handleInput = (e: any, index: number) => {
        const val = e.nativeEvent.data;
        if (isNaN(Number(val))) {
            e.preventDefault();
            const [left, right] = val.split("-");
            if (isNaN(left) || isNaN(right)) {
                setTimeout(() => {
                    setVerificationCode({
                        ...verificationCode,
                        [index]: "0"
                    })
                }, 1)
                return;
            }
            const value = String(left) + String(right);
            if (value.length > 1) {
                let fullVals: { [key: number]: string } = {};
                value.slice(0, 6).split('').map(each => {
                    if (index < 6) {
                        fullVals[index] = each;

                    }
                    index++
                });
                setVerificationCode(fullVals);
                return;
            }
        }
    }

    return (
        <div className="code-inputs-box">
            {
                Array.from({ length: 3 }, (_, index) => (
                    <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="number"
                        name={index.toString()}
                        maxLength={1}
                        value={verificationCode[index] || ''}
                        onInput={e => handleInput(e, index)}
                        onChange={(e) => handleInputChange(e, index)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        className="code-inputs"
                        autoComplete="off"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        style={{
                            color: verificationCodeError[index] ? "red" : "#292929"
                        }}
                    />
                ))
            }
            <div className="code-inputs-separator" />
            {
                Array.from({ length: 3 }, (_, index) => (
                    <input
                        key={index + 3}
                        ref={(el) => (inputRefs.current[index + 3] = el)}
                        type="number"
                        name={(index + 3).toString()}
                        maxLength={1}
                        value={verificationCode[index + 3] || ''}
                        onChange={(e) => handleInputChange(e, index + 3)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => handleKeyDown(e, index + 3)}
                        className="code-inputs"
                        autoComplete="off"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        style={{
                            color: verificationCodeError[index + 3] === true ? "red" : "#292929"
                        }}
                    />
                ))
            }
        </div>
    )
}

export default CodeInputs;