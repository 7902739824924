import { useEffect } from "react";
import { CountryProvider } from "./context/Country.conext";
import { AuthProvider } from "./context/TokenContext";
import Router from "./router/useRoutes";
import { PopUpProvider } from "./context/PopUp.context";

const App = () => {

    useEffect(() => {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            // When ready, auto-scroll 1px to hide URL bar
            window.addEventListener("load", function () {
                // Set a timeout...
                setTimeout(function () {
                    // Hide the address bar!
                    window.scrollTo(0, 1);
                }, 0);
            });
        }
    }, []);

    return (
        <AuthProvider>
            <CountryProvider>
                <PopUpProvider>
                    <Router />
                </PopUpProvider>
            </CountryProvider>
        </AuthProvider>
    );
}

export default App;
