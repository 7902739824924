import { useEffect, useState } from "react";
import ButtonGB from "../components/ButtonGB";
import { accountInt, useDataPackage } from "../context/DataPackage.context";
import { useChange } from "../context/Change.context";
import { formatMoney } from "../functions/formats";
import "./styles/destDetail.css";


interface DestDetailInt {
    innerBenef: number;
    send: () => void;
}

const DestDetail = ({ innerBenef, send }: DestDetailInt) => {

    const { beneficiaries } = useDataPackage();
    const [allInfo, setAllInfo] = useState<accountInt>();
    const { to, toOp } = useChange();

    useEffect(() => {
        if (beneficiaries) {
            const allData = beneficiaries.find(({ alias_id }) => alias_id === innerBenef);
            if (allData) {
                setAllInfo(allData);
            }
        }
    }, [innerBenef, beneficiaries]);

    const handleConvert = (op: string) => {
        return op === "TransferenciaArgentinaARS" ? "Pesos Argentinos" : op === "PayPalUSD" ? "Dólares" : "Pesos Colombianos";
    }


    return (
        <div className="dest-detail-box">
            <h3>Verifica los datos</h3>
            {
                allInfo && (
                    <div>
                        <p style={{textAlign: 'center'}}><small>Se enviarán <b>${formatMoney(to)} {handleConvert(toOp)}</b> a la siguiente cuenta:</small></p>
                        <br />
                        <div className="dest-detail-row">
                            <p>{allInfo.client_name}</p>
                            <p><small>{allInfo.doc_type} </small>{allInfo.doc_num}</p>
                        </div>

                        <div className="dest-detail-row">
                            <p>{allInfo.account_bank}</p>
                            <p>
                                {
                                    allInfo.account_type !== "wallet" && <small>{allInfo.account_type.toUpperCase()} </small>
                                }
                                <b>{allInfo.account_num}</b>
                            </p>
                        </div>

                        <div className="dest-detail-button">
                            <ButtonGB
                                title="Enviar"
                                onClick={send}
                            />
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default DestDetail;