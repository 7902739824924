import { useEffect, useState } from "react";
import { useInput, useInputGroup } from "../custom/useInput";
import { useDataPackage } from "../context/DataPackage.context";
import { useChange } from "../context/Change.context";
import useOrdersService from "../services/orders.service";
import { useFormApp } from "appdland-ui";

interface OptionsInt {
    label: string;
    value: any;
    extra?: string | React.ReactElement;
}

const useSend = () => {
    const { setterLimitMin, limitMin, limitMax, fromOp, toOp, from, to, currentSide } = useChange();
    const { register, formValues, validateForm, setForm } = useFormApp();
    const { personalInfo, beneficiaries } = useDataPackage();
    const { send } = useOrdersService();


    const [formVals, setFormVals] = useInputGroup([
        "_client_name",
        "_doc_num",
        "_acc_num"
    ]);
    const [docType, setDocType] = useInput();
    const [accType, setAccType] = useInput();
    const [bank, setBank] = useInput();
    const [beneff, setBeneff] = useState<OptionsInt[]>([]);
    const [innerBenef, setInnerBenef] = useState(0);
    const [selectBlocker, setSelectBlocker] = useState(true);
    const [country, setCountry]: ["ARG" | "COL" | "USA", React.Dispatch<React.SetStateAction<"ARG" | "COL" | "USA">>] = useState(
        toOp.slice(-3) === "ARS" ? "ARG" : toOp.slice(-3) === "COP" ? "COL" : "USA");

    // const [alias, setAlias] = useState('');


    useEffect(() => {
        if (limitMax.state === true || from === 0) {
            setSelectBlocker(true);
        } else {
            setSelectBlocker(false);
        }
    }, [limitMax, limitMin]);


    useEffect(() => {
        setCountry(toOp.slice(-3) === "ARS" ? "ARG" : toOp.slice(-3) === "COP" ? "COL" : "USA")
    }, [toOp]);

    //NEW LINE
    useEffect(() => setInnerBenef(0), [fromOp, toOp]);
    //

    useEffect(() => {
        const benef: OptionsInt[] = [{
            label: "Nuevo Destinatario",
            value: 1,
            extra: "-"
        }];
        if (beneficiaries) {
            beneficiaries.filter(benefInfo => benefInfo.operation === toOp).map(({ alias_id, alias_name }) => {
                benef.push({
                    label: alias_name,
                    value: alias_id,
                    extra: "*"
                });
            });
            setBeneff(benef);
        }
    }, [beneficiaries, toOp]);

    useEffect(() => {
        document.title = "Enviar | ColCambios";
    }, []);


    const handleSendDest = async () => {
        if (limitMin.number > from) {
            setterLimitMin(true);
            return
        }

        if (formValues.innerBenef === 0 || formValues.innerBenef === 1) {
            return;
        }

        send({
            from_data: fromOp,
            to_data: toOp,
            val_from: from,
            val_to: to,
            beneficiary: innerBenef,
            operation: currentSide
        });
    }

    const handleValidateForm = () => {
        if (limitMin.number > from) {
            setterLimitMin(true);
            return false;
        }

        let validator = validateForm();


        const [firstName] = formValues.client_name.split(" ");

        if (firstName.length > 12) {
            validator = false;
            setForm('client_name', formValues.client_name, true, 'No parece un nombre');
        }

        return validator;
    }

    const handleSend = (offer_id?: number) => {
        const { client_name, account_num, doc_num, } = formValues;
        const account_bank = toOp === "NequiCOP"
            ? "Nequi"
            : toOp === "DaviplataCOP"
                ? "Daviplata"
                : toOp === "BancolombiaCOP"
                    ? "Bancolombia"
                    : toOp === "PayPalUSD"
                        ? "PayPal"
                        : formValues.account_bank
        const account_type = toOp === "NequiCOP" || toOp === "DaviplataCOP"
            ? "wallet"
            : toOp === "PayPalUSD"
                ? "email"
                : formValues.account_type
        const doc_type = country === "ARG"
            ? "DNI"
            : toOp === "PayPalUSD"
                ? "PAS"
                : formValues.doc_type

        //alias
        const [firstName, sureName] = formValues.client_name.split(" ");
        const realSureName = sureName ? sureName : "";
        const completeAlias = `${firstName} ${realSureName}`;

        send({
            from_data: fromOp,
            to_data: toOp,
            val_from: from,
            val_to: to,
            beneficiary: {
                account_alias: {
                    client_name,
                    alias_name: completeAlias.length > 20 ? firstName : completeAlias,
                    operation: toOp
                },
                account: {
                    account_bank,
                    account_country: country,
                    account_num,
                    account_type,
                    doc_num: toOp === "PayPalUSD" ? '00000' : doc_num,
                    doc_type
                }
            },
            operation: currentSide,
            offer_id
        })
    }

    return {
        country,
        docType,
        handleSend,
        handleValidateForm,
        toOp,
        beneff,
        handleSendDest,
        selectBlocker,
        register,
        formValues,
        innerBenef,
        setInnerBenef,
    };
}

export default useSend;
