import { useLocation, useNavigate } from "react-router-dom";
import "./styles/error.css";
import ButtonGB from "../components/ButtonGB";
import { useEffect } from "react";

const ErrorPage = () => {

    const { state } = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Error | ColCambios";
    }, []);

    return (
        <section className="error-page">
            <div className="error-page-box">
                <div className="error-page-box-decoration" />
                <div className="error-page-box-decoration" />
                <div className="error-page-box-decoration" />
                <h1>Algo no salió bien</h1>
                {
                    state && "errorMessage" in state && (
                        <p>{state.errorMessage === "service" ? "Servicio temporalmente no disponible" : "Se ha producido un error desconocido"}</p>
                    )
                }
                <div className="error-page-small">
                    <p>Te pedimos disculpas, puedes volver a intentarlo. Si el problema perciste puedes contactarnos para recibir ayuda personalizada.</p>
                </div>
                <ButtonGB 
                    onClick={() => navigate("/")}
                    title="Volver"
                />
            </div>
        </section>
    )
}

export default ErrorPage;