import offerIMG from "../../assets/images/offer.svg";
import "./styles/beneficios.css";

const Beneficios = () => {

    return (
        <div className="benef-section">
            <h2 className="section-title-gb">¡Regístrate y Accede a Beneficios!</h2>
            <div className="benef-content">
                <div className="benef-content-list">
                    <li>Sin costos o comisiones extra</li>
                    <li>Rápido, fácil y seguro</li>
                    <li>Ofertas exclusivas</li>
                    <li>Comodidad al alcance de tu mano</li>
                </div>
                <img
                    alt="imagen de oferta"
                    src={offerIMG}
                />
            </div>

        </div>
    )
}

export default Beneficios;