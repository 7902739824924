import { FaCheck } from "react-icons/fa6";
import InputGB from "../../components/InputGB";
import { InputAppProps } from "appdland-ui";
import passwordValidator from 'password-validator';
import { useState } from "react";
import "./styles/formpass.css";

interface RegisterPassInt {
    password: Omit<InputAppProps, 'type' | 'onBlur' | 'placeholder'>;
    confPassword: Omit<InputAppProps, 'type' | 'onBlur' | 'placeholder'>;
    passwordValue: string;
    confPasswordValue: string;
    schema: passwordValidator;
}

interface validPassInt {
    length: boolean;
    type: boolean;
}

const FormPass = ({ password, confPassword, passwordValue, confPasswordValue, schema }: RegisterPassInt) => {

    const [isValidPass, setIsValidPass] = useState<validPassInt>();
    const [passMatch, setPasMatch] = useState(false);

    schema
        // .is().min(8)
        .has().lowercase()
        .has().digits(1)
        .not().spaces();


    const handleValPass = () => {
        const isValidPassword = schema.validate(passwordValue);
        setIsValidPass({
            length: passwordValue.length < 8 ? false : true,
            type: isValidPassword === false ? false : true
        });
        if (confPasswordValue.length > 0) {
            handleConfirmPass();
        }
    }

    const handleConfirmPass = () => {
        const match = passwordValue === confPasswordValue;
        if (!match) {
            setPasMatch(true);
        } else {
            setPasMatch(false);
        }
    }

    return (
        <div className="register-form-body-content-pass">
            <div className="register-form-body-content-input">
                <InputGB
                    {...password}
                    type="password"
                    onBlur={handleValPass}
                    placeholder="Crear Contraseña"
                />
                <div className="register-form-password-validator">
                    <p>La contraseña debe contener</p>
                    <p style={{
                        fontWeight: passwordValue !== "" ? "bold" : "normal",
                        color: isValidPass && isValidPass.length === false ? "red" : "rgba(0, 78, 119, 0.8)"
                    }}>
                        Mínimo 8 carácteres{isValidPass?.length && <FaCheck color="green" style={{ margin: "0px 5px" }} />}
                    </p>
                    <p style={{
                        fontWeight: passwordValue !== "" ? "bold" : "normal",
                        color: isValidPass && isValidPass.type === false ? "red" : "rgba(0, 78, 119, 0.8)"
                    }}>
                        Letras y números{isValidPass?.type && <FaCheck color="green" style={{ margin: "0px 5px" }} />}
                    </p>
                </div>
            </div>
            <div className="register-form-body-content-input">
                <InputGB
                    {...confPassword}
                    type="password"
                    onBlur={handleConfirmPass}
                    placeholder="Confirmar Contraseña"
                />
                <div className="register-form-password-validator">
                    {
                        passMatch && (
                            <p style={{ color: "red" }}>La contraseña no coincide</p>
                        )
                    }
                </div>

            </div>

        </div>
    )
}

export default FormPass;