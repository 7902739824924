interface BankType {
    value: string;
    label: string;
    extra?: string;
}

interface BankTypes {
    [key: string]: BankType[];
}

export const bankTypes: BankTypes = {
    COL: [
        { value: "Ban100", label: "Ban100" },
        { value: "BBVA", label: "BBVA" },
        { value: "Bancamia S.A.", label: "Bancamia S.A." },
        { value: "Bancoldex S.A", label: "Bancoldex S.A" },
        { value: "Bancoomeva", label: "Bancoomeva" },
        { value: "Banco AV Villas", label: "Banco AV Villas" },
        { value: "Banco Agrario", label: "Banco Agrario" },
        { value: "Banco BTG Pactual", label: "Banco BTG Pactual" },
        { value: "Banco Caja Social", label: "Banco Caja Social" },
        { value: "Banco Cooperativo Coopcentral", label: "Banco Cooperativo Coopcentral" },
        { value: "Banco Contactar", label: "Banco Contactar" },
        { value: "Banco Credifinanciera", label: "Banco Credifinanciera" },
        { value: "Banco Falabella", label: "Banco Falabella" },
        { value: "Banco GNB Sudameris", label: "Banco GNB Sudameris" },
        { value: "Banco Mundo Mujer", label: "Banco Mundo Mujer" },
        { value: "Banco Pichincha", label: "Banco Pichincha" },
        { value: "Banco Popular", label: "Banco Popular" },
        { value: "Banco Procredit", label: "Banco Procredit" },
        { value: "Banco Santander", label: "Banco Santander" },
        { value: "Banco Serfinanza", label: "Banco Serfinanza" },
        { value: "Banco W S.A", label: "Banco W S.A" },
        { value: "Banco de Bogotá", label: "Banco de Bogotá" },
        { value: "Banco de Occidente", label: "Banco de Occidente" },
        { value: "Banco Western Union", label: "Banco Western Union" },
        { value: "Bold CF", label: "Bold CF" },
        { value: "Citybank", label: "Citybank" },
        { value: "Coink", label: "Coink" },
        { value: "Coltefinanciera S.A", label: "Coltefinanciera S.A" },
        { value: "Confiar Cooperativa Financiera", label: "Confiar Cooperativa Financiera" },
        { value: "Davivienda", label: "Davivienda" },
        { value: "Ding Tecnipagos S.A", label: "Ding Tecnipagos S.A" },
        { value: "Global66", label: "Global66" },
        { value: "JP Morgan Colombia S.A", label: "JP Morgan Colombia S.A" },
        { value: "JFK Cooperativa Financiera", label: "JFK Cooperativa Financiera" },
        { value: "Lulo Bank S.A", label: "Lulo Bank S.A" },
        { value: "IRIS", label: "IRIS" },
        { value: "Itaú", label: "Itaú" },
        { value: "Movii", label: "Movii" },
        { value: "NU", label: "NU" },
        { value: "Pibank", label: "Pibank" },
        { value: "Powwi", label: "Powwi" },
        { value: "Rappipay", label: "Rappipay" },
        { value: "Scotiabank Colpatria S.A", label: "Scotiabank Colpatria S.A" },
        { value: "Ualá", label: "Ualá" },
    ],
    ARG: [
        { value: "Belo", label: "Belo" },
        { value: "Lemon Cash", label: "Lemon Cash" },
        { value: "MercadoPago", label: "MercadoPago" },
        { value: "NaranjaX", label: "NaranjaX" },
        { value: "Prex", label: "Prex" },
        { value: "Ualá", label: "Ualá" },
        { value: "Reba", label: "Reba" },
        { value: "Personal Pay", label: "Personal Pay" },
        { value: "Nubi", label: "Nubi" },

        { value: "Bancor", label: "Bancor" },
        { value: "Banco Ciudad de Buenos Aires", label: "Banco Ciudad de Buenos Aires" },
        { value: "Banco Galicia", label: "Banco Galicia" },
        { value: "Banco Hipotecario", label: "Banco Hipotecario" },
        { value: "Banco Itaú", label: "Banco Itaú" },
        { value: "Banco Macro", label: "Banco Macro" },
        { value: "Banco Nación", label: "Banco Nación" },
        { value: "Banco Patagonia", label: "Banco Patagonia" },
        { value: "Banco Provincia", label: "Banco Provincia" },
        { value: "Banco Santander", label: "Banco Santander" },
        { value: "Banco San Juan", label: "Banco San Juan" },
        { value: "Banco Supervielle", label: "Banco Supervielle" },

        { value: "BBVA Francés", label: "BBVA Francés" },
        { value: "Brubank", label: "Brubank" },

        { value: "Citi Bank", label: "Citi Bank" },

        { value: "HSBC", label: "HSBC" },

        { extra: "ICBC", value: "ICBC Industrial And Commercial Bank Of China", label: "Industrial And Commercial Bank Of China" },

        { value: "Wilobank", label: "Wilobank" },
        { value: "Otros", label: "Otros" },
    ],
    USA: [
        { value: "email", label: "Email" }
    ]
}