import axios from "axios";
import { useLoading } from "../context/LoadingContext";
import useServices from "./services.service";
import { useDataPackage } from "../context/DataPackage.context";

interface accountAliasInt {
    alias_id?: number;
    alias_name: string;
    client_name: string;
}

interface accountInt {
    account_id?: number;
    doc_type: string;
    doc_num: string;
    account_type: string;
    account_num: string;
    account_country: string;
    account_bank: string;
    balance?: number;
}

interface deletInt {
    alias_id: number;
    account_id: number;
}

interface accountInfoInt {
    account_alias: accountAliasInt;
    account: accountInt;
}

const useAccountsService = () => {
    const { setLoading } = useLoading();
    const { manageError, buildHeader } = useServices();
    const { reqData } = useDataPackage();
    const deleteAcc = async (dataReq: deletInt) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_ACC_DELETE || "", dataReq, {
                headers: buildHeader()
            });
            setLoading(false);
        } catch (error) {
            // const errorMessage = manageError(error);
            // setLoading(false);
            // if (errorMessage) {
            //     if (errorMessage === "doc_type format not valid") {
            //         throw "docType";
            //     } else if (errorMessage === "doc_num format not valid") {
            //         throw "docNum";
            //     } else if (errorMessage === "operation limit out of range") {
            //         throw "limit";
            //     } else {
            //         throw errorMessage
            //     }
            // }
        }
    }
    const editAcc = (dataReq: accountInfoInt) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_ACC_EDIT || "", dataReq, { headers: buildHeader() })
            .then(data => {
                window.location.reload();
                reqData();
            })
            .catch(err => manageError(err))
            .finally(() => setLoading(false))
    }
    const createAcc = async (dataReq: accountInfoInt) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_ACC_ADD || "", dataReq, {
                headers: buildHeader()
            });
            setLoading(false);
        } catch (error) {
            const errorMessage = manageError(error);
            console.log(error);

            setLoading(false);
            // if (errorMessage) {
            //     if (errorMessage === "doc_type format not valid") {
            //         throw "docType";
            //     } else if (errorMessage === "doc_num format not valid") {
            //         throw "docNum";
            //     } else if (errorMessage === "operation limit out of range") {
            //         throw "limit";
            //     } else {
            //         throw errorMessage
            //     }
            // }
        }
    }
    return {
        deleteAcc,
        editAcc,
        createAcc
    }
}

export default useAccountsService;