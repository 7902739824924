import { FormApp } from "appdland-ui";
import ButtonGB from "../../components/ButtonGB";
import { usePopUp } from "../../context/PopUp.context";
import './styles/firstcall.css';

interface FirstCallInt {
    sendCode: () => void;
    validateForm: () => boolean;
    input: React.ReactNode
}

const FirstCall = ({ sendCode, validateForm, input }: FirstCallInt) => {
    const { setPopUp } = usePopUp();


    const forgotUser = () => {
        setPopUp({
            title: 'Olvidé Mi Usuario',
            content: <PopUpContent />
        })
    }

    return (
        <FormApp onSubmit={sendCode} validateForm={validateForm} className="forgot-form">
            <p>Necesitamos validar tu identidad, por favor <b>ingresa tu número de documento</b> para continuar</p>
            {input}
            <ButtonGB
                title="Continuar"
            />
            <p
                className="forgot-button"
                onClick={forgotUser}
            >
                Olvidé mi usuario
            </p>
        </FormApp>
    )
}

const PopUpContent = () => (
    <div>
        <p>
            Recuerda que tu usuario corresponde al
            <b> número de documento</b> con el que te has registrado.
            Si tienes problemas para ingresar, por favor comunícate con nuestro servicio de atención al cliente.
        </p>
    </div>
)

export default FirstCall;