import { useState } from "react";

interface ValidateInputInt {
    value: string | number ;
    validate: boolean;
}

const modifyHook = (setState: any, keysValues: any) => {
    setState((prevState: any) => ({
        ...prevState,
        ...keysValues
    }));
};

export const useInput = (params?:ValidateInputInt):[ ValidateInputInt, ( value: number | string, validate?:boolean ) => void ] => {

    const [state, setState] = useState<ValidateInputInt>({
        value: params?.value || '',
        validate: params?.validate || false
    });

    const setter = ( value: number|string, validate:boolean = false ) => {
        modifyHook(setState,{
            value: value,
            validate: validate
        })
    }

    return [state, setter];
}

// export default useInput;

interface GroupInt {
    [key:string]: ValidateInputInt;
}

export const useInputGroup = ( keys:string[] ):[GroupInt, ( value:number|string, key:string, validate?:boolean ) => void] => {
    
    const group:GroupInt = {};
    keys.map( (key ) => {
        group[key] = {
            value: '',
            validate: false
        }
    });

    // console.log(group);

    const [state, setState] = useState(group);

    const setter = ( value:number|string, key:string, validate:boolean = false ) => {
        modifyHook(setState, {
            [key]: {
                value: value,
                validate: validate
            }
        });
    }

    return [state, setter];
}