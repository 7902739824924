import { FormApp, useFormApp } from "appdland-ui";
import ButtonGB from "../../components/ButtonGB";
import InputGB from "../../components/InputGB";
import useAuthService from "../../services/auth.service";
import { useState } from "react";
import { usePopUp } from "../../context/PopUp.context";
import TitleGB from "../../components/TitleGB";
import WhatsAppInput, { WhatsAppInputRegisterConfig } from "../Register/WhatsAppInput";
import "./styles/wpchange.css";

export interface WhApp {
    whatsapp: number;
    password: string;
}

const WpChange = () => {
    const { register, formValues, validateForm, setForm } = useFormApp();
    const { wpChange } = useAuthService();
    const { setPopUp } = usePopUp();

    const [countrySel, setCountrySel] = useState(0);
    // const [wp, setWp] = useState("");

    const onSubmitWp = () => {

        wpChange(Number(String(countrySel) + String(formValues.whatsapp)), formValues.password)
            .catch((error) => {
                if (error && "title" in error) {
                    if (error.title === "Verify Reception" || error.title === "send whatsapp") {
                        setPopUp({
                            title: "No Pudimos Contactarte",
                            content: "No fue posible contactarte al número de WhatsApp que ingresaste, por favor verifica que el número está bien escrito y la bandera corresponda al país de tu WhatsApp."
                        });
                    } else if (error.title === "try") {
                        setPopUp({
                            title: "Ups",
                            content: error.message
                        });
                    } else if (error.title === "check password") {
                        setForm("password", formValues.password, true, error.message)
                    } else if (error.title === "wp") {
                        setForm("whatsapp", formValues.whatsapp, true, "No se puede usar este WhatsApp")
                    }
                } else {
                    setPopUp({
                        title: "Error",
                        content: "Error desconocido, por favor contactar a soporte"
                    });
                }
            })


    }

    return (
        <FormApp
            onSubmit={onSubmitWp}
            validateForm={validateForm}
            className="change-wp-body"
        >
            <TitleGB>Cambiar WhatsApp</TitleGB>
            <WhatsAppInput
                {...register('whatsapp', WhatsAppInputRegisterConfig as any)}
                whatsapp={formValues.whatsapp}
                setForm={setForm}
                country={countrySel}
                setCountry={setCountrySel}
            />
            <InputGB
                {...register("password")}
                placeholder="Ingresa tu Contraseña"
                type="password"
            />
            <ButtonGB
                title="Cambiar"
            />
        </FormApp>
    )
}

export default WpChange;