import { FormApp, useFormApp } from "appdland-ui";
import ButtonGB from "../../components/ButtonGB";
import InputGB from "../../components/InputGB";
import { useState } from "react";
import useLoginService from "../../services/login.service";
import FormPass from "../Register/FormPass";
import passwordValidator from 'password-validator';

interface ChangePassInt {
    user: string;
    onSuccess: () => void;
    onError?: () => void;
}

const ChangePass = ({ user, onSuccess, onError }: ChangePassInt) => {

    const { register, setForm, formValues, validateForm } = useFormApp();
    const { forgotThirdCall } = useLoginService();
    const schema = new passwordValidator();

    const [passMatch, setPassMatch] = useState(false);


    const handleConfirmPass = () => {
        const match = formValues.newPassword === formValues.confirmPass;
        if (!match) {
            setPassMatch(true);
        } else {
            setPassMatch(false);
        }
    }

    const changePass = () => {
        forgotThirdCall(user, formValues.newPassword)
            .then(() => {
                onSuccess();
            })
            .catch((err) => {
                setForm('newPassword', '', true);
                setForm('confirmPass', '', true);
                if (onError && err === "Se ha agotado el tiempo, por favor inicia el proceso nuevamente") {
                    onError();
                }
            })
    }

    const handleValidateForm = () => {
        let validator = validateForm();

        if (passMatch) {
            validator = false
        }

        return validator;
    }

    return (
        <FormApp onSubmit={changePass} validateForm={handleValidateForm} className="forgot-form">
            <FormPass
                password={{ ...register('newPassword', { min: 6, max: 200 }) }}
                passwordValue={formValues.newPassword}
                confPassword={{ ...register('confirmPass', { min: 6, max: 200 }) }}
                confPasswordValue={formValues.confirmPass}
                schema={schema}
            />
            {/* <div className="register-form-body-content pass">
                <InputGB
                    {...register('newPassword', { min: 6, max: 200 })}
                    placeholder="Crear Contraseña"
                    type="password"
                    errorMessage="Debes ingresar tu contraseña"
                    onBlur={handleConfirmPass}
                />
                <InputGB
                    {...register('confirmPass', { min: 6, max: 200 })}
                    placeholder="Confirmar Contraseña"
                    type="password"
                    errorMessage="Debes confirmar tu contraseña"
                    onBlur={handleConfirmPass}
                />
                {
                    passMatch && (
                        <p style={{ color: "red", textAlign: 'center' }}>La contraseña no coincide</p>
                    )
                }
            </div> */}
            <br />
            <ButtonGB
                title="Cambiar"
            />
        </FormApp>
    )
}

export default ChangePass;