import useServices from "./services.service";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLoading } from "../context/LoadingContext";
import { useAuth } from "../context/TokenContext";
import { usePopUp } from "../context/PopUp.context";

const useAuthService = () => {

    const { setLoading } = useLoading();
    const { manageError, buildHeader } = useServices();
    const navigate = useNavigate();
    const { setToken } = useAuth();
    const { setPopUp } = usePopUp();

    const forgotChangePass = (password: string, newPassword: string) => {

        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}first-change-password`, { password, newPassword }, { headers: buildHeader() })
            .then(() => {
                navigate("../codigo-cambio-contraseña");
            })
            .catch(error => {
                manageError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const validCode = (tempCode: number) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}change-password`, { tempCode }, { headers: buildHeader() })
            .then(() => {
                setToken(undefined);
                setPopUp({
                    title: 'Hecho',
                    content: 'Se ha cambiado correctamente tu contraseña, ahora puedes ingresar con la nueva',
                    onClose: () => {
                        setLoading(true);
                        window.location.reload();
                    }
                })
            })
            .catch(error => {
                manageError(error);
                if (error.response.data.title === 'timeout') {
                    navigate('/perfil/mis-datos')
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const wpChange = async (whatsapp: number, password: string) => {
        setLoading(true)
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}client-first-change-whatsapp`, { whatsapp, password }, { headers: buildHeader() })

            navigate("../codigo-cambio-whatsapp");
        } catch (error) {
            throw manageError(error, true)
        } finally {
            setLoading(false)
        }
    }

    const validCodeWp = (tempCode: number) => {
        setLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}client-change-whatsapp`, { tempCode }, { headers: buildHeader() })
            .then(() => {
                navigate("/perfil/mis-datos");
                setPopUp({
                    title: 'Hecho',
                    content: 'Se ha cambiado correctamente tu whatsApp',
                    onClose: () => {
                        setLoading(true);
                        window.location.reload();
                    }
                })
            })
            .catch(error => {
                manageError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return {
        forgotChangePass,
        wpChange,
        validCode,
        validCodeWp
    }
}

export default useAuthService;