import comprobante from '../assets/images/comprobante.png';
interface prfInt {
    title: string;
    info: string | React.ReactNode;
}

interface groupInt {
    titleGroup: string;
    groupInfo: prfInt[];
}

const createGroup = ({ titleGroup, groupInfo }: groupInt) => ({ titleGroup, groupInfo });

//EJEMPLO
const proceso = createGroup({
    titleGroup: "Acceso y Envíos",
    groupInfo: [
        {
            title: '¿Como me registro?',
            info: <p>Al iniciar, verás la opción de <b>Perfil</b>. Allí, selecciona <b>Registrarme</b> para comenzar tu registro.</p>
        },
        {
            title: '¿Cómo realizo un envío?',
            info: <div>
                <p>El primer paso es registrarte en nuestra página web. Luego, en tu perfil, selecciona la opción <b>Enviar</b>, donde podrás cotizar y agregar los datos del destinatario <small>(estos datos se guardarán automáticamente para futuros envíos)</small></p>
                <br />
                <p>Para más detalles, puedes ver nuestros videos del paso a paso en nuestro canal de <a href="https://www.youtube.com/@colcambios4600" target="_blank">YouTube</a>.</p>
            </div>
        },
        {
            title: '¿Cómo puedo cambiar mi contraseña?',
            info: <p>En la sección de inicio de sesión le das clic al botón <b>olvidé mi contraseña</b>, para verificar que eres tú, te pedirá el numero de documento y enviará un código al número de WhatsApp asignado</p>
        },
        {
            title: '¿Cuánto tiempo tengo para realizar el pago?',
            info: <div>
                <p>El tiempo para realizar tu pago puede variar según el método de pago, lo más normal es de <b>30 minutos máximo</b></p>
                <br />
                <p>En este tiempo debes pagar y subir el comprobante, cuando el tiempo se acabe la orden de envío se cancelará automáticamente</p>
            </div>
        },
        {
            title: "Realice el pago, pero la orden se canceló ¿Qué debo hacer?",
            info: <div>
                <p>Si realizaste el pago y la orden se canceló por tiempo, debes comunicarte directamente al WhatsApp de la empresa <a href="https://api.whatsapp.com/send?phone=573212686456&text=Hola,%20realic%C3%A9%20el%20pago%20por%20un%20env%C3%ADo%20pero%20se%20cancel%C3%B3%20por%20tiempo"><b>+57 321 268 6456</b></a> allí te daremos una solución.</p>
                <br />
                <p><b>IMPORTANTE: </b>No generes de nuevo una orden de envío, ya que pueden salirte otros datos de pago.</p>
            </div>
        },
        {
            title: '¿Cuáles comprobantes son válidos y no válidos?',
            info: <div>
                <p>Los comprobantes válidos son aquellos que incluyen los siguientes datos:</p>
                <br />
                <ul>
                    <li>Nombre de la persona que envió</li>
                    <li>Cuenta a donde realizó el pago/transferencia/depósito</li>
                    <li>Monto</li>
                    <li>Fecha y hora</li>
                    <li>Referencia de comprobante, número de transacción o identificación del comprobante</li>
                    <br />
                    <p><small>Ejemplo de comprobante válido</small></p>
                    <img alt="ejemplo de comprobante válido" src={comprobante} style={{ height: '500px' }} />
                </ul>
                <br />
                <br />
                <p>En caso de que el comprobante sea incorrecto, el sistema lo rechazará. Esto no sucede de forma inmediata, así que debes estar pendiente para así subir un comprobante correcto</p>
            </div>
        },
        {
            title: 'Escribí mal los datos del envió, ¿qué puedo hacer?',
            info: <div>
                <p>En nuestro sistema, validamos los datos de los destinatarios antes de procesar cualquier envío.</p>
                <p>Si los datos son incorrectos, te notificaremos por WhatsApp. Podrás corregirlos directamente en <b>Perfil &gt; Mis envíos &gt; Orden</b>. Una vez actualizados, se validarán para proceder con el envío.</p>
            </div>
        },
        {
            title: '¿Me gustaría saber si tienen envíos a otra moneda que no está en la página?',
            info: <p>Solo podrás hacer envíos con las monedas disponibles en el momento, nos encantaría poder ofrecerte más opciones en un futuro. Trabajamos constantemente para mejorar.</p>
        },
        {
            title: '¿Cuánto tarda en llegar el dinero?',
            info: <p>Los envíos se atienden por orden de llegada, en ocasiones aumenta la demanda y los envíos pueden llegar a tardar más de lo normal. En promedio estos se hacen en 3 horas desde que se verifican los pagos, pero recuerda que según nuestros términos y condiciones tenemos un máximo de 48 horas para realizarlos.</p>
        }
    ]
});

const cancelaciones = createGroup({
    titleGroup: "Cancelaciones",
    groupInfo: [
        {
            title: 'Quiero cancelar la orden, pero ya realicé el pago',
            info: 'Si deseas cancelar tu orden, pero ya el dinero fue enviado, debes comunicarte directamente al WhatsApp.'
        },
        {
            title: 'Quiero cancelar mi orden sin haber realizado el pago',
            info: <div>
                <p>Para poder cancelar tu orden lo puedes hacer directamente en el botón <b>Cancelar Envío</b></p>
                <br />
                <p>Este botón lo encontrarás al final del detalle de tu orden</p>
            </div>
        }
    ]
});


const general = createGroup({
    titleGroup: "Consultas Generales",
    groupInfo: [
        {
            title: '¿Es una empresa Legal?',
            info: <div>
                <p>Sí, somos una empresa totalmente legal y nuestro centro de operación se ubica en Colombia. Puedes consultar con nuestro NIT 1.125.231.722-5, para corroborar la legalidad de la empresa.</p>
                <p>Puedes buscar directamente en la página de la cámara y comercio de Bogotá Cámara de Comercio de Bogotá <a href='https://ccb.org.co'>ccb.org.co</a> con el NIT o nombre de la empresa, se encuentra con la matricula activa, es decir, ColCambios esta activa como empresa para seguir operando.</p>
            </div>
        },
        {
            title: '¿Puedo usar la cuenta de otra persona para hacer el pago?',
            info: <div>
                <p>Por seguridad se recomienda ser el titular de la cuenta para realizar los pagos, en caso contrario se atiene a posibles consecuencias si se presentase</p>
                <p>En ColCambios queremos que su dinero siempre este seguro y para ello nos regimos ante las leyes de cada país.</p>
            </div>
        },
        {
            title: '¿Qué pasa si mi orden no se ha realizado dentro del límite de 48 horas?',
            info: <div>
                <p>En caso de que ColCambios no haya realizado su orden en el tiempo máximo estipulado, tendrá derecho a una devolución total de su dinero, o una nueva cotización con un mejor precio.</p>
                <p>También podrá recibir ofertas exclusivas para su próximo envío, como modo de compensación por la falta ocurrida.</p>
            </div>
        },
        {
            title: '¿En dónde me puedo quejar por conflictos en mi orden?',
            info: <div>
                <p>En caso de que el usuario considere que existió un mal accionar por parte de ColCambios debe presentar su queja, inquietud o reclamo antes de realizar alguna acción legal, ColCambios al recibir su queja, inquietud o reclamo tiene 15 días para resolverlo (esto según la ley 527 de 1999).</p>
                <p>Las redes sociales como WhatsApp, Instagram y Facebook se encuentran disponibles para la solución de cualquier queja, inquietud o reclamo; el usuario también puede hacer uso del correo electrónico oficial de ColCambios <b>oficialcolcambios@gmail.com</b></p>
                <br />
                <p>Si el usuario no realizo ningún procedimiento anterior para expresar su problema, inquietud, queja o reclamo no está en condiciones de poder acceder a la ley de protección al consumidor, por ende, cualquier accionar legal que quiera llevar en contra de ColCambios no será válido.</p>
            </div>
        },
        {
            title: '¿Envían mis datos y registro a la AFIP o la DIAN?',
            info: <div>
                <p>Los datos no son enviados a ningún ente regulador como la AFIP en Argentina o la DIAN en Colombia, salvo en condiciones muy específicas como:</p>
                <ul>
                    <li>Sospechas de algún ente regulador sobre movimientos que pueden afectar la seguridad nacional o internacional</li>
                    <li>Sospechas por movimientos a cuentas investigadas por terrorismo, narcotráfico y / o relaciones ilegales.</li>
                </ul>
                <br />
                <br />
                <p>El usuario es responsable de cualquier vínculo que exista con la cuenta de destino, ya que ColCambios es un intermediario de un intercambio de divisas, el cual recibe y da en mismo valor otra moneda sin tener ningún tipo de relación con el usuario o destinatario.</p>
            </div>
        }
    ]
});

const comisiones = createGroup({
    titleGroup: 'Comisiones y Pagos',
    groupInfo: [
        {
            title: '¿Cobran comisiones o costos extra?',
            info: 'No, los precios que cotizas en nuestra web están totalmente libres de comisiones extra.'
        },
        {
            title: '¿Qué divisas ofrecemos?',
            info: <div>
                <p>Las monedas que ofrecemos son por medio de transferencias:</p>
                <ul>
                    <li>Pesos Argentinos (ARS)</li>
                    <li>Pesos Colombianos (COP)</li>
                    <li>Dólar Estadunidense (USD)</li>
                </ul>
            </div>
        },
        {
            title: '¿Qué medios de pagos ofrecen?',
            info: <div>
                <p>Los medios de pago son:</p>
                <ul>
                    <li>Pesos Colombianos (Transferencias interbancarias, Nequi, Bancolombia, Corresponsales y cajeros)</li>
                    <li>Pesos Argentinos (Mercado Pago, Ualá o cualquier Banco de Argentina)</li>
                    <li>Dólar Estadounidense (PayPal)</li>
                </ul>
            </div>
        },
        {
            title: 'Quiero enviar más del límite permitido, ¿qué puedo hacer?',
            info: <div>
                <p>Si el monto que quieres enviar supera los límites máximos de nuestra página web, puedes hacer varios envíos hasta llegar al monto que deseas enviar. Recuerda que no tenemos limite en cuanto a la cantidad de envíos.</p>
                <br />
                <p>En caso de enviar una cantidad muy grande, puedes comunicarte al WhatsApp <b>+57 321 268 6456</b> y allí te daremos una solución.</p>
            </div>
        },
        {
            title: '¿Cubren las comisiones de los bancos?',
            info: 'ColCambios únicamente cubre las comisiones cuando vas a recibir el dinero, en el caso contrario de que tengas que realizar el pago eres responsable de los descuentos, comisiones o impuestos que imponga tu entidad bancaria.'
        }
    ]
});

const paypal = createGroup({
    titleGroup: 'PayPal',
    groupInfo: [
        {
            title: '¿Quiero recibir en PayPal, tengo que cubrir la comisión?',
            info: <div>
                <p>ColCambios cubre las comisiones de PayPal para enviar y recibir el dinero.</p>
                <p>Esto quiere decir que el monto que verás en nuestra página es exactamente el que recibirás o el que debes enviar.</p>
            </div>
        },
        {
            title: '¿Qué pasa si al recibir el dinero en PayPal esta retenido?',
            info: <div>
                <p>En ocasiones puedes ver que el dinero que recibiste fue <b>RETENIDO</b>(congelado) en PayPal por unos días, que te imposibilita poder hacer uso de este.</p>
                <p>Si te llega a suceder puedes contactarnos para hacer que PayPal te libere el dinero un poco más rápido, sin embargo, es PayPal quien toma estas medidas.</p>
                <br />
                <small>Para obtener información de como liberar ese saldo en tu cuenta ingresa aquí Mi pago está retenido. ¿Por qué me está sucediendo esto? <a href='https://paypal.com'>paypal.com</a></small>
            </div>
        },
        {
            title: '¿Cómo puedo cambiar mi saldo de PayPal?',
            info: <div>
                <p>El primer paso es registrarte en nuestra página web. Luego, en tu perfil, selecciona la opción <b>Enviar</b>, donde podrás cotizar y agregar los datos del destinatario <small>estos datos se guardarán automáticamente para futuros envíos</small></p>
                <br />
                <p>
                Para más detalles, puedes ver nuestros videos del paso a paso en nuestro canal de YouTube.</p>
            </div>
        }
    ]
})

export const preguntasData = [
    proceso,
    cancelaciones,
    general,
    comisiones,
    paypal
];