import axios from "axios";
import { useLoading } from "../context/LoadingContext";
import useServices from "./services.service";
import { useDataPackage } from "../context/DataPackage.context";
import { useNavigate } from "react-router-dom";

interface accountAliasInt {
    alias_name: string;
    client_name: string;
    operation: string //toOp
}

interface accountInt {
    doc_type: string;
    doc_num: string;
    account_type: string;
    account_num: string;
    account_country: "ARG" | 'COL' | "USA";
    account_bank: string;
}

interface beneficiaryInt {
    account_alias: accountAliasInt;
    account: accountInt;
}

interface sendInt {
    from_data: string;
    to_data: string;
    val_from: number;
    val_to: number;
    beneficiary: beneficiaryInt | number;
    operation: 'left' | 'right';
    offer_id?: number;
}

const useOrdersService = () => {
    const { setLoading } = useLoading();
    const { manageError, buildHeader } = useServices();
    const { reqData } = useDataPackage();

    const navigate = useNavigate();


    const send = (orderInfo: sendInt) => {
        setLoading(true);

        axios.post(process.env.REACT_APP_ORDER_CREATE || "", orderInfo, { headers: buildHeader() })
            .then(async ({ data }) => {
                await reqData();
                navigate(`/perfil/envios/${data}`);
            })
            .catch(error => {
                manageError(error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const cancelOrder = (orderId: string) => {
        setLoading(true);

        axios.post(process.env.REACT_APP_ORDER_CANCEL || "", { orderId, whoCancel: "client" }, { headers: buildHeader() })
            .then(async () => {
                // await reqData();
                window.location.reload();
            })
            .catch(error => manageError(error))
    }

    const getCompleteOrder = async (orderId: string) => {
        try {
            setLoading(true);
            return await refreshOrder(orderId)
        } finally {
            setLoading(false);
        }
    }

    const refreshOrder = async (orderId: string) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ORDER_REFRESH}/${orderId}`, { headers: buildHeader() })
            return data;
        } catch (error) {
            manageError(error);
            navigate(`/perfil/envios`);
        }
    }

    return {
        send,
        cancelOrder,
        refreshOrder,
        getCompleteOrder
    }
}

export default useOrdersService;