import { FormApp, useFormApp } from "appdland-ui";
import ButtonGB from "../../components/ButtonGB";
import { useState } from "react";
import { accountInt } from "../../context/DataPackage.context";
import InputGB from "../../components/InputGB";
import SelectGB from "../../components/SelectGB";
import { docTypes } from "../../data/docTypes";
import { bankTypes } from "../../data/bankTypes";
import { accTypes } from "../../data/accTypes";
import useAccountsService from "../../services/accounts.service";
import './styles/editaccount.css';

interface EditAccountInt {
    account: accountInt;
}

const EditAccount = ({ account }: EditAccountInt) => {

    const [state, setState] = useState(false);
    const { register, validateForm, formValues, setForm } = useFormApp();
    const { editAcc } = useAccountsService();

    const handleValidateForm = () => {
        let validator = validateForm();
        //VALIDAR QUE LA CUENTA SEA DIFERENTE A LA ANTIGUA
        return validator;
    }

    const handleSubmit = () => {

        const { client_name, doc_num, account_num } = formValues;

        const account_bank = account.operation === "NequiCOP"
            ? "Nequi"
            : account.operation === "DaviplataCOP"
                ? "Daviplata"
                : formValues.account_bank
        const account_type = account.operation === "NequiCOP" || account.operation === "DaviplataCOP"
            ? "wallet"
            : formValues.account_type
        const doc_type = account.account_country === "ARG"
            ? "DNI"
            : formValues.doc_type

        editAcc({
            account_alias: {
                alias_id: account.alias_id,
                alias_name: account.alias_name,
                client_name
            },
            account: {
                account_id: account.account_id,
                doc_type,
                doc_num,
                account_type,
                account_num,
                account_country: account.account_country,
                account_bank,
                balance: 0
            }
        })
    }

    return (
        <div className="editaccount-box">
            <p className="editaccount-message">Parece que algo no coincide en la cuenta que ingresaste, por favor corrige los datos</p>
            {
                state === false && (
                    <ButtonGB
                        title="Corregir"
                        onClick={() => setState(true)}
                    />
                )
            }
            {
                state && (
                    <FormApp onSubmit={handleSubmit} validateForm={handleValidateForm} className="editaccount-form-box">
                        <InputGB
                            {...register('client_name')}
                            type="text"
                            capitalize
                            placeholder="Nombre Completo"
                            defaultValue={account.client_name}
                        />
                        {
                            account.account_country !== 'USA' && (
                                <>
                                    {
                                        account.account_country !== 'ARG' && (
                                            <SelectGB
                                                {...register('doc_type')}
                                                options={docTypes[account.account_country]}
                                                placeholder="Tipo de Documento"
                                                defaultValue={account.doc_type}
                                            />
                                        )
                                    }
                                    <InputGB
                                        {...register('doc_num')}
                                        type="number"
                                        placeholder={`Número de ${account.account_country === 'ARG' ? 'DNI' : formValues.doc_type !== '' ? formValues.doc_type : 'Documento'}`}
                                        defaultValue={account.doc_num}
                                    />
                                    {
                                        account.operation !== 'NequiCOP' && account.operation !== 'DaviplataCOP' && (
                                            <>
                                                <SelectGB
                                                    {...register('account_bank')}
                                                    options={bankTypes[account.account_country]}
                                                    placeholder="Banco"
                                                    defaultValue={account.account_bank}
                                                />
                                                <SelectGB
                                                    {...register('account_type')}
                                                    options={accTypes[account.account_country]}
                                                    placeholder="Tipo de Cuenta"
                                                    defaultValue={account.account_type}
                                                />
                                            </>

                                        )
                                    }
                                </>
                            )
                        }

                        <InputGB
                            {...register('account_num')}
                            type={account.account_country === 'ARG' ? 'text' : account.account_country === 'USA' ? 'email' : 'number'}
                            placeholder={account.account_country !== 'USA' ? "Número de Cuenta" : "Correo Eléctronico"}
                            defaultValue={account.account_num}
                        />
                        <ButtonGB validateSubmit>Corregir</ButtonGB>
                    </FormApp>
                )
            }
        </div>
    )
}

export default EditAccount;