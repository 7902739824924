import forgotImage from "../../assets/images/forgot.svg";
import './styles/decorationmessage.css';

const DecorationMessage = () => (
    <div className="forgot-decoration">
        <img
            alt="forgot-image"
            src={forgotImage}

        />
        <p>
            <small>
                Si olvidaste tu contraseña, te guiaremos paso a paso para restablecerla de forma segura.
            </small>
        </p>
    </div>
)


export default DecorationMessage;