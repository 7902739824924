import { useNavigate } from "react-router-dom"
import { useAuth } from "../context/TokenContext";
import { usePopUp } from "../context/PopUp.context";
import axios from "axios";
import { useLoading } from "../context/LoadingContext";

const useServices = () => {
    const navigate = useNavigate();
    const { token, setToken } = useAuth();
    const { setPopUp } = usePopUp();
    const { setLoading } = useLoading();

    /**
     * Manejo global de errores
     * @param error objeto error de la peticion
     * @param reqMessage True, si se requiere el mensaje de error
     */
    const manageError = (error: any, reqMessage = false) => {
        console.clear();
        if (error.response) {
            const number = error.response.status as number;
            console.log(number);
            switch (number) {
                case 401:
                    setPopUp({
                        content: "No tienes permiso para realizar esta acción",
                        title: "Sin Autorización"
                    });
                    break;
                case 402:
                    //parametros incorrectos
                    setPopUp({
                        content: "Error inesperado, por favor informar a soporte.",
                        title: "Error"
                    });
                    break;
                case 403:
                    setPopUp({
                        content: "Se ha vencido tu sesión, por favor vuelve a iniciar de nuevo.",
                        title: "Atención"
                    });
                    setTimeout(() => {
                        setToken(undefined);
                    }, 5000);
                    break;
                case 500:
                    setPopUp({
                        content: "Ha ocurrido un error inesperado, te pedimos disculpas. Si el problema persiste por favor comunicate con nosotros.",
                        title: "Algo no ha salido bien"
                    });
                    break;
                case 502:
                    if (reqMessage) {
                        return { message: error.response.data.message as String, title: error.response.data.title as String };
                    } else {
                        setPopUp({
                            content: error.response.data.message,
                            title: "Atención"
                        });
                    }
                    break;
                case 505:
                    setPopUp({
                        content: "Ha ocurrido un error inesperado, te pedimos disculpas. Si el problema persiste por favor comunicate con nosotros.",
                        title: "Algo no ha salido bien"
                    });
                    break;
                default:
                    navigate("/error");
                    break;
            }

        } else if (error.request) {
            navigate("/error", {
                state: {
                    errorMessage: "service"
                }
            })
        } else {
            navigate("/error");
        }
    }

    const buildHeader = () => {
        return {
            "auth-token": token,
            "auth-role": "clients"
        }
    }

    /**
     * 
     * @param state 
     * @param setter 
     * @param url url sin el path del api y sin slash
     */
    const getGeneral = (state: any, setter: (param: any) => void, url: string) => {
        if (!state) {
            setLoading(true);
            axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
                timeout: 6000,
                headers: buildHeader()
            })
                .then(response => setter(response.data))
                .catch(error => manageError(error))
                .finally(() => setLoading(false))
        }
    }

    return {
        manageError,
        buildHeader,
        getGeneral
    }
}

export default useServices;