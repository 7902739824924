import InputGB from "../components/InputGB";
import { useInput, useInputGroup } from "../custom/useInput";
import useAccountsService from "../services/accounts.service";
import SelectGB from '../components/SelectGB';
import { avalaibleCountries } from "../data/availableCountries";
import { bankTypes } from "../data/bankTypes";
import { docTypes } from "../data/docTypes";
import { accTypes } from "../data/accTypes";
import ButtonGB from "../components/ButtonGB";
import { useNavigate } from "react-router-dom";
import { useDataPackage } from "../context/DataPackage.context";
import "./styles/benefCreate.css";

const BenefCreate = () => {
    const navigate = useNavigate();
    const { personalInfo } = useDataPackage();
    const { createAcc } = useAccountsService();
    const [formVals, setFormVals] = useInputGroup([
        "_client_name",
        "_doc_num",
        "_acc_num"
    ]);
    const [country, setCountry] = useInput();
    const [docType, setDocType] = useInput();
    const [accType, setAccType] = useInput();
    const [bank, setBank] = useInput();
    const handleSubmit = (e: any) => {
        e.preventDefault();
        let validator = false;
        const _name = String(formVals._client_name.value);
        const _alias = `${_name.split(" ")[0]} ${_name.split(" ")[1]}`;
        let _doc_type = String(docType.value);
        let _doc_num = String(formVals._doc_num.value);
        let _bank = String(bank.value);
        let _acc_type = String(accType.value);
        const _acc_num = String(formVals._acc_num.value);

        if (_name.length < 8) {
            setFormVals(_name, "_client_name", true);
            validator = true;
        }

        if (country.value !== 'USA') {
            if (country.value !== 'ARG') {
                if (_doc_type === '') {
                    setDocType(_doc_type, true);
                    validator = true;
                }
            } else {
                _doc_type = 'DNI'
            }

            if (_doc_num.length < 5) {
                setFormVals(_doc_num, "_doc_num", true);
                validator = true;
            }
            if (_acc_type === '') {
                setAccType(_acc_type, true);
                validator = true;
            }

            if (_acc_num.length < 5) {
                setFormVals(_acc_num, "_acc_num", true);
                validator = true;
            }
            // if (toOp === 'NequiCOP') {
            //     _bank = 'Nequi'
            // } else if (_bank === '') {
            //     setBank(_bank, true);
            //     validator = true;
            // }
        } else {
            _acc_type = 'email'
            _doc_type = String(personalInfo?.doc_type) || '';
            _doc_num = String(personalInfo?.client_id) || '';
            _bank = 'PayPal'

        }

        if (validator === true) {
            return;
        }

        // createAcc({
        //     account_alias: {
        //         client_name: _name,
        //         alias_name: _alias
        //     },
        //     account: {
        //         doc_type: _doc_type,
        //         doc_num: _doc_num,
        //         account_type: _acc_type,
        //         account_num: _acc_num,
        //         account_country: country.value === "COL" || country.value === "ARG" || country.value === "USA" ? country.value : 'COL',
        //         account_bank: _bank,
        //         account_limit: 1
        //     }
        // }).then(() => {
        //     navigate('/perfil/destinatarios')
        // }).catch(error => {
        //     console.log(error);
        //     navigate('/error')
        // })
    }
    return (
        <form onSubmit={handleSubmit} className="benef-create-box">
            <div className="benef-create-title">
                <h2 className="section-title-gb">Agregar Destinatario</h2>
                <p>Rápido y fácil, al agregar un destinatario ahorras tiempo en tus envíos</p>
                <p><b>Ingresa la información completa de la persona que recibe tus envíos</b></p>
            </div>

            <div className="benef-create-input">
                {/* <InputGB
                    value={formVals._client_name.value}
                    onChange={(value) => setFormVals(value, "_client_name", false)}
                    type="name"
                    capitalize
                    errorState={formVals._client_name.validate}
                    placeHolder="Nombre Completo"
                /> */}
            </div>
            <div className="benef-create-input">
                {/* <SelectGB
                    value={String(country)}
                    onChange={setCountry}
                    options={avalaibleCountries}
                    errorState={country.validate}
                    placeHolder="Pais"
                /> */}
            </div>
            {
                country.value && (
                    <>
                        <div className="benef-create-input">
                            {/* <SelectGB
                                value={String(docType)}
                                onChange={setDocType}
                                options={docTypes[country.value]}
                                errorState={docType.validate}
                                placeHolder="Tipo de Documento"
                            /> */}
                        </div>

                        {
                            docType.value && (
                                <div className="benef-create-input">
                                    {/* <InputGB
                                        value={formVals._doc_num.value}
                                        onChange={(value) => setFormVals(value, "_doc_num", false)}
                                        type="number"
                                        integerStrict={true}
                                        errorState={formVals._doc_num.validate}
                                        placeHolder={`Número de ${country.value === 'ARG' ? 'DNI' : docType.value !== '' ? docType.value : 'Documento'}`}
                                    /> */}
                                </div>
                            )
                        }
                        <div className="benef-create-input">
                            {/* <SelectGB
                                value={String(bank)}
                                onChange={setBank}
                                options={bankTypes[country.value]}
                                errorState={bank.validate}
                                placeHolder="Banco"
                            /> */}
                        </div>

                        {
                            bank.value && (
                                <>
                                    <div className="benef-create-input">
                                        {/* <SelectGB
                                            value={String(accType)}
                                            onChange={setAccType}
                                            options={accTypes[country.value]}
                                            errorState={accType.validate}
                                            placeHolder="Tipo de Cuenta"
                                        /> */}
                                    </div>

                                    {
                                        accType.value && (
                                            <>
                                                <div className="benef-create-input">
                                                    {/* <InputGB
                                                        value={formVals._acc_num.value}
                                                        onChange={(value) => setFormVals(value, "_acc_num", false)}
                                                        type={country.value === 'ARG' ? 'text' : country.value === 'USA' ? 'email' : 'number'}
                                                        errorState={formVals._acc_num.validate}
                                                        placeHolder={country.value !== 'USA' ? "Número de Cuenta" : "Correo Electrónico"}
                                                    /> */}
                                                </div>
                                                {formVals._acc_num.value && <ButtonGB />}
                                            </>
                                        )
                                    }
                                </>
                            )
                        }
                    </>
                )
            }
        </form>
    )
}

export default BenefCreate;