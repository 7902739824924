import { createContext, useContext, useEffect, useState } from "react";
import { useChanges } from "./Changes.context";
import { useCountry } from "./Country.conext";
import { formatMoney } from "../functions/formats";

interface limitInt {
    state: boolean;
    message: string;
    number: number;
}

interface ContextChangeInt {
    fromOp: string;
    setFromOp: (param: string) => void;
    from: number;
    setFrom: (param: number) => void;
    toOp: string;
    setToOp: (param: string) => void;
    to: number;
    setTo: (param: number) => void;
    block: boolean;
    currentSide: 'left' | 'right';
    setCurrentSide: (param: "left" | "right") => void;
    limitMin: limitInt;
    limitMax: limitInt;
    setterLimitMin: (param: boolean) => void;
}

const ChangeContext = createContext<ContextChangeInt | null>(null);


interface ChangeProps {
    children: React.ReactNode;
}

const ChangeProvider: React.FC<ChangeProps> = ({ children }) => {

    const [fromOp, setFromOp] = useState("");
    const [from, setFrom] = useState(0);
    const [toOp, setToOp] = useState("");
    const [to, setTo] = useState(0);
    const [currentSide, setCurrentSide] = useState<"left" | "right">("left");
    const [block, setBlock] = useState(false);
    const [limitMin, setLimitMin] = useState<limitInt>({
        state: false,
        message: "Mínimo 0",
        number: 0
    });
    const [limitMax, setLimitMax] = useState<limitInt>({
        state: false,
        message: "Máximo 0",
        number: 0
    });

    const { changes, changesState } = useChanges();
    const { country } = useCountry();

    useEffect(() => {
        const completeOp = {
            fromOp,
            from,
            toOp,
            to
        };
        sessionStorage.setItem("_change_op", JSON.stringify(completeOp));
    }, [fromOp, from, toOp, to]);

    useEffect(() => {
        const recovered = sessionStorage.getItem("_change_op");
        if (recovered) {
            const completeOp = JSON.parse(recovered);
            setFromOp(completeOp.fromOp);
            setFrom(completeOp.from);
            setToOp(completeOp.ToOp);
            setTo(completeOp.to);
        }
    }, []);

    useEffect(() => {
        if (changes && changesState) {
            const change = changes.find(({ currency_from, currency_get }) => currency_from === fromOp && currency_get === toOp);
            const _limitMin = Number(changesState.find(({ change_name }) => change_name === change?.currency_from)?.limit_min) || 0;
            const _limitMax = Number(changesState.find(({ change_name }) => change_name === change?.currency_get)?.limit_max) || 0;
            setLimitMin((prev) => (
                {
                    ...prev,
                    message: `Mínimo $ ${formatMoney(_limitMin, 0)}`,
                    number: _limitMin
                }
            ));
            setLimitMax((prev) => (
                {
                    ...prev,
                    message: `Máximo $ ${formatMoney(_limitMax, 0)}`,
                    number: _limitMax
                }
            ));
            
            if (change) {
                setBlock(false);
                switch (currentSide) {
                    case "left":
                        const fromResult = (Number(from) * change.base_price) - Number(change.fee);
                        const fromOperation = change.currency_get.slice(-3) === "USD" ? Number(fromResult.toFixed(2)) : Math.round(fromResult);
                        setTo(fromOperation > 0 ? fromOperation : 0);
                        if (fromOperation > _limitMax) {
                            setLimitMax(prev => ({
                                ...prev,
                                state: true
                            }));
                        } else {
                            setLimitMax(prev => ({
                                ...prev,
                                state: false
                            }));
                        }
                        break;
                    case "right":
                        const toResult = (Number(to) + Number(change.fee)) / change.base_price
                        const toOperation = change.currency_from.slice(-3) === "USD" ? Number(toResult.toFixed(2)) : Math.round(toResult);
                        setFrom(toOperation > 0 ? toOperation : 0);
                        if (Number(to) > _limitMax) {
                            setLimitMax(prev => ({
                                ...prev,
                                state: true
                            }));
                        } else {
                            setLimitMax(prev => ({
                                ...prev,
                                state: false
                            }))
                        }
                        break;
                }

            } else {
                setBlock(true);
            }
        }
    }, [from, to, fromOp, toOp, changes, currentSide])

    useEffect(() => {
        switch (country) {
            case "CO":
                setFromOp("NequiCOP");
                setToOp("TransferenciaArgentinaARS");
                break;
            case "AR":
                setFromOp("TransferenciaArgentinaARS");
                setToOp("NequiCOP");
                break;
            default:
                try {
                    setFromOp("PayPalUSD");
                    setToOp("NequiCOP");
                } catch (error) {
                    setFromOp("TransferenciaArgentinaARS");
                    setToOp("NequiCOP");
                }
                break;
        }
    }, [country]);


    const setterLimitMin = (state: boolean) => {
        setLimitMin(prev => ({
            ...prev,
            state
        }));
    }

    return (
        <ChangeContext.Provider value={
            {
                fromOp,
                setFromOp,
                from,
                setFrom,
                toOp,
                setToOp,
                to,
                setTo,
                block,
                currentSide,
                setCurrentSide,
                limitMin,
                limitMax,
                setterLimitMin
            }
        }>
            {children}
        </ChangeContext.Provider>
    );
}


const useChange = (): ContextChangeInt => {
    const context = useContext(ChangeContext);
    if (!context) {
        throw new Error('useChange debe ser utilizado dentro de ChangeProvider');
    }
    return context;
};

export { ChangeProvider, useChange };