import React from "react";

interface ItemType {
    label: string;
    value: string;
    extra: string | React.ReactNode;
}

interface ItemTypes {
    [key: string]: ItemType[];
}

const extra = (arg: string) => (<p style={{marginRight: "20px"}}>{arg}</p>)

export const docTypes: ItemTypes = {
    COL: [
        { label: "Cédula de Ciudadanía", value: "CC", extra: extra("CC") },
        { label: "Cédula de Extrangería", value: "CE", extra: extra("CE") },
        { label: "Tarjeta de Identidad", value: "TI", extra: extra("TI") },
        { label: "Nro de Identificación Tributario", value: "NIT", extra: extra("NIT") },
        { label: "Pasaporte", value: "PAS", extra: extra("PAS") },
    ],
    ARG: [
        { label: "Documento Nacional de Identidad", value: "DNI", extra: extra("DNI") },
        { label: "Pasaporte", value: "PAS", extra: extra("PAS") },
    ],
    USA: [
        { label: "Pasaporte", value: "PAS", extra: extra("PAS") }
    ]
}
