import { formatMoney, formatSeparate } from "../../functions/formats";
import bancoAr from '../../assets/images/BancoAR.png';
import bancoCo from '../../assets/images/BancoCO.png';
import bancolombia from '../../assets/images/bancolombia.png';
import daviplata from '../../assets/images/Daviplata.png';
import nequi from '../../assets/images/Nequi.png';
import paypal from '../../assets/images/PayPal.png';
import trans from '../../assets/images/trans.png';
import "./styles/detail.css";

const Detail = ({ option, operation, amount, realAmount, biggerLength }: { option: string, operation: string, amount: number, realAmount?: number, biggerLength: number }) => (
    <div className="order-resume-detail">
        <p>{option}</p>
        <div className="order-resume-operation">
            <img
                alt="Imagen que indica el banco correspondiente a la operación"
                src={
                    operation === "NequiCOP"
                        ? nequi
                        : operation === "DaviplataCOP"
                            ? daviplata
                            : operation === "BancolombiaCOP"
                                ? bancolombia
                                : operation === "BancosdeColombiaCOP"
                                    ? bancoCo
                                    : operation === "TransferenciaArgentinaARS"
                                        ? trans
                                        : operation === "DepositoEfectivoARS"
                                            ? bancoAr
                                            : operation === "PayPalUSD"
                                                ? paypal
                                                : undefined
                }
            />
            {/* <p style={{width: `${biggerLength * 6}px`}}>{formatSeparate(operation.slice(0, -3))}</p> */}
        </div>
        {
            realAmount && amount !== realAmount ? (
                <div>
                    <p style={{ fontSize: 'x-small', color: 'gray', textDecoration: 'line-through', margin: 0 }}>${formatMoney(amount, operation === "PayPalUSD" ? 2 : 0)}</p>
                    <p className="order-resume-mount">${formatMoney(realAmount, operation === "PayPalUSD" ? 2 : 0)}</p>
                </div>
            ) : (
                <p className="order-resume-mount">${formatMoney(amount, operation === "PayPalUSD" ? 2 : 0)}</p>
            )
        }
    </div>
)

export default Detail;