import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from "../pages/Error";
import Send from "../modules/Send";
import Benef from "../modules/Benef";
import BenefCreate from "../modules/BenefCreate";
import { ProtectedRoute } from "./ProtectedRoute";
import { PreventLogin } from "./PreventLogin";
import Orders from "../modules/Order/Orders";
import User from "../modules/User/User";
import { ChangesProvider } from "../context/Changes.context";
import { ChangeProvider } from "../context/Change.context";
import { Loading } from "../components/Loading";
import Order from "../modules/Order/Order";
import Recover from "../pages/Recover";
import SendDirect from "../modules/SendDirect";
import { DataPackageProvider } from "../context/DataPackage.context";
import Tyc from "../pages/Tyc";
import Legal from "../pages/Legal";
import WpChange from "../modules/User/WpChange";
import PassChange from "../modules/User/PassChange";
import PassChangeCode from "../modules/User/PassChangeCode";
import WpChangeCode from "../modules/User/WpChangeCode";
import Offers from "../modules/Offers/Offers";
import OfferSend from "../modules/Offers/OfferSend";
import Preguntas from "../pages/Preguntas";

const Index = lazy(() => import("../pages/Index"));
const SignIn = lazy(() => import("../pages/SignIn"));
const LogIn = lazy(() => import("../pages/LogIn"));
const Profile = lazy(() => import("../pages/Profile"));
const Prices = lazy(() => import("../pages/Prices"));

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="*"
                    element={
                        <Suspense fallback={<Loading />}>
                            <ChangesProvider>
                                <ChangeProvider>
                                    <DataPackageProvider>
                                        <Routes>
                                            <Route path="/" element={<Index />} />
                                            <Route path="registrarse" element={<SignIn />} />
                                            <Route path="recuperar" element={<Recover />} />
                                            <Route path="precios" element={<Prices />} />
                                            <Route path="preguntas" element={<Preguntas />} />
                                            <Route path="tyc" element={<Tyc />} />
                                            <Route path="legal" element={<Legal />} />

                                            <Route element={<PreventLogin />}>
                                                <Route path="ingresar" element={<LogIn />} />
                                            </Route>

                                            <Route element={<ProtectedRoute />}>
                                                <Route path="perfil" element={<Profile />}>
                                                    <Route path="mis-datos" element={<User />} >
                                                        <Route path="cambiar-whatsapp" element={<WpChange />} />
                                                        <Route path="codigo-cambio-whatsapp" element={< WpChangeCode />} />
                                                        <Route path="cambiar-contraseña" element={<PassChange />} />
                                                        <Route path="codigo-cambio-contraseña" element={< PassChangeCode />} />
                                                    </Route>
                                                    <Route path="enviar" element={<Send />} />
                                                    <Route path="envios" element={<Orders />} />
                                                    <Route path="envios/:orderId" element={<Order />} />
                                                    <Route path="destinatarios" element={<Benef />}>
                                                        <Route path="crear" element={<BenefCreate />} />
                                                        <Route path="enviar-directo/:benef?" element={<SendDirect />} />
                                                    </Route>
                                                    <Route path="ofertas" element={<Offers />} />
                                                    <Route path="enviar-oferta/:offer" element={<OfferSend />} />
                                                </Route>
                                            </Route>

                                            <Route>
                                                <Route path="afiliado" />
                                            </Route>
                                        </Routes>
                                    </DataPackageProvider>
                                </ChangeProvider>
                            </ChangesProvider>
                        </Suspense>
                    }
                />
                <Route path="error" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );

};

export default Router;
