import { useEffect, useState } from "react";
import { completeOrderInt, useDataPackage } from "../../context/DataPackage.context";
import { useNavigate } from "react-router-dom";
import { LuClock } from "react-icons/lu";
import "./styles/timer.css";

interface TimerInt {
    order: completeOrderInt;
}

const Timer = ({ order }: TimerInt) => {
    const { reqData } = useDataPackage();
    const [time, setTime] = useState<{ minutes: string, seconds: string }>();
    const navigate = useNavigate();

    // useEffect(() => {
    //     reqData();
    // }, []);

    useEffect(() => {
        if (order && order.difference !== 0 && order.order_state === "created") {

            const calculateTimeLeft = () => {

                const formatTime = (time: number) => (time < 10 ? `0${time}` : String(time));

                const { difference } = order;
                const minutes = formatTime(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
                const seconds = formatTime(Math.floor((difference % (1000 * 60)) / 1000));

                order.difference -= 1000;

                return { minutes, seconds };
            };

            const timer = setInterval(() => {
                const time = calculateTimeLeft();
                if (time.minutes === "00" && time.seconds === "01") {
                    navigate("/perfil/envios");
                    reqData();
                } else {
                    setTime(time);
                }
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [order, navigate]);

    return (
        <div className="colcambios-timer">
            <LuClock />
            <p>
                <b>
                    {
                        time ? (
                            time.minutes + ":" + time.seconds
                        ) : "--:--"
                    }
                </b>
            </p>
        </div>
    )
}

export default Timer;