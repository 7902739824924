import { useEffect, useState } from "react";
import ChangerComplex from "./ChangerComplex";
import { useChanges } from "../context/Changes.context";
import { GoArrowSwitch } from "react-icons/go";
import { useChange } from "../context/Change.context";
import { formatSeparate } from "../functions/formats";

//IMAGES
import bancolombiaIMG from "../assets/images/bancolombia.png";
import bancosdeColombiaIMG from "../assets/images/BancoCO.png";
import daviplataIMG from "../assets/images/Daviplata.png";
import depositoArgentinaIMG from "../assets/images/BancoAR.png";
import nequiIMG from "../assets/images/Nequi.png";
import paypalIMG from "../assets/images/PayPal.png";
import transferenciaArgentinaIMG from "../assets/images/trans.png";

import "./styles/changer.css";

interface optionsInt {
    value: string;
    label: string;
    image: string;
}

interface movementInt {
    rotate: "180deg" | "270deg";
    fixed: "0deg" | "90deg";
}

interface ChangerInt {
    half?: boolean;
    offer?: boolean;
}

const Changer = ({ half = false, offer = false }: ChangerInt) => {

    const { changesState } = useChanges();

    const [optionsFrom, setOptionsFrom] = useState<optionsInt[]>();
    const [optionsTo, setOptionsTo] = useState<optionsInt[]>();
    const [options, setOptions] = useState<optionsInt[]>();
    const [defaultFromValue, setDefaultFromValue] = useState<optionsInt>();
    const [defaultToValue, setDefaultToValue] = useState<optionsInt>();
    const [movement, setMovement] = useState<movementInt>({
        rotate: "180deg",
        fixed: "0deg"
    });
    const [iconRotation, setIconRotation] = useState("0deg");
    const { fromOp, setFromOp, from, setFrom, toOp, setToOp, to, setTo, limitMin, limitMax } = useChange();

    useEffect(() => {
        if (changesState) {
            const list: optionsInt[] = [];
            changesState.forEach(({ change_name }) => {
                let image = "";
                switch (change_name) {
                    case "BancolombiaCOP":
                        image = bancolombiaIMG;
                        break;
                    case "BancosdeColombiaCOP":
                        image = bancosdeColombiaIMG;
                        break;
                    case "DaviplataCOP":
                        image = daviplataIMG;
                        break;
                    case "DepositoEfectivoARS":
                        image = depositoArgentinaIMG;
                        break;
                    case "NequiCOP":
                        image = nequiIMG;
                        break;
                    case "PayPalUSD":
                        image = paypalIMG;
                        break;
                    case "TransferenciaArgentinaARS":
                        image = transferenciaArgentinaIMG;
                        break;
                };
                const label = formatSeparate(change_name.slice(0, -3));
                const option: optionsInt = {
                    value: change_name,
                    image: image,
                    label
                }
                list.push(option);
            });
            setOptions(list.reverse());
        }
    }, [changesState]);

    useEffect(() => {
        if (options) {
            const fromList = options.filter(({ value }) => value.slice(-3) !== toOp.slice(-3));
            const found = options.find(({ value: fval }) => fval === fromOp);
            setDefaultFromValue(found)
            setOptionsFrom(fromList);
        }
    }, [fromOp, options]);

    useEffect(() => {
        if (options) {
            const toList = options.filter(({ value }) => value.slice(-3) !== fromOp.slice(-3));
            const found = options.find(({ value: fval }) => fval === toOp);
            setDefaultToValue(found)
            setOptionsTo(toList);
        }
    }, [toOp, options]);

    const handleRevert = () => {
        if (options) {
            const fromList = options.filter(({ value }) => value.slice(-3) !== fromOp.slice(-3));
            const toList = options.filter(({ value }) => value.slice(-3) !== toOp.slice(-3));
            setOptionsFrom(fromList);
            setOptionsTo(toList);
            setFromOp(toOp);
            setToOp(fromOp);
            if (iconRotation === movement.rotate) {
                setIconRotation(movement.fixed);
            } else {
                setIconRotation(movement.rotate);
            }
        }

    }

    useEffect(() => {
        if (window.innerWidth < 1050 && movement.fixed === "0deg") {
            setMovement({
                rotate: "270deg",
                fixed: "90deg"
            });
            setIconRotation("90deg");
        }

        if (window.innerWidth > 1050 && movement.fixed === "90deg") {
            setMovement({
                rotate: "180deg",
                fixed: "0deg"
            });
            setIconRotation("0deg");
        }
    }, [movement]);


    return (
        <div>
            {
                optionsFrom && (
                    <div className="changerCT-container">
                        {
                            offer === false && (
                                <div className="changerCT-box">
                                    <ChangerComplex
                                        value={fromOp}
                                        setvalue={setFromOp}
                                        title="Envías"
                                        options={optionsFrom}
                                        inputValue={from}
                                        setInputValue={setFrom}
                                        side="left"
                                        limit={limitMin}
                                        defaultValue={defaultFromValue}
                                        decimal={fromOp.slice(-3) === "USD" ? 2 : 0}
                                    />
                                </div>
                            )
                        }
                        {
                            half === false && optionsTo && (
                                <>
                                    {
                                        offer === false && (
                                            <div className="changerCT-inverter">
                                                <div className="changerCT-inverter-circle" onClick={handleRevert}>
                                                    <GoArrowSwitch size={20} color="gray" style={{
                                                        rotate: iconRotation,
                                                        cursor: "pointer",
                                                        transitionDuration: "0.5s",
                                                        color: "#E6754E"
                                                    }} />
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="changerCT-box">
                                        <ChangerComplex
                                            value={toOp}
                                            setvalue={setToOp}
                                            title="Recibes"
                                            options={optionsTo}
                                            inputValue={to}
                                            setInputValue={setTo}
                                            side="right"
                                            limit={limitMax}
                                            defaultValue={defaultToValue}
                                            hideInput={offer}
                                            decimal={toOp.slice(-3) === "USD" ? 2 : 0}
                                        />
                                    </div>
                                </>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}

export default Changer;