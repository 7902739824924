import axios from "axios";
import { useLoading } from "../context/LoadingContext";
import useServices from "./services.service";
import { useDataPackage } from "../context/DataPackage.context";

interface payInt {
    file: any;
    payment_id: number;
}

const usePaymentsService = () => {

    const { setLoading } = useLoading();
    const { buildHeader, manageError } = useServices();
    const { reqData } = useDataPackage();


    const pay = async (dataReq: payInt) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('payment_id', String(dataReq.payment_id));
            formData.append('image', dataReq.file);

            await axios.post(process.env.REACT_APP_PAYMENT_PAY || "", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...buildHeader()
                }
            });
            window.location.reload();
        } catch (error) {
            manageError(error);
            setLoading(false);
        } finally {
            await reqData();
        }
    }

    const getImage = async (payment_id: number) => {

        try {
            setLoading(true);

            const { data } = await axios.get(`${process.env.REACT_APP_PAYMENT_GET}/${payment_id}`, { headers: buildHeader() })
            const imageUrl = `data:image/png;base64,${data}`;
            return imageUrl;
        } catch (error) {
            throw error;
        } finally {
            console.clear();
            setLoading(false);
        }
    }

    const changePay = (data: any) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_PAYMENT_CHANGE || "", data, { headers: buildHeader() })
            .then(() => window.location.reload())
            .catch(error => manageError(error))
    }

    const confirmPay = (payment_id: string) => {
        setLoading(true);
        axios.post(process.env.REACT_APP_PAYMENT_CONFIRM || "", { payment_id }, { headers: buildHeader() })
            .then(() => window.location.reload())
            .catch(error => manageError(error))
    }

    return {
        pay,
        getImage,
        changePay,
        confirmPay
    }
}

export default usePaymentsService;