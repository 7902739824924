import { BsCopy } from "react-icons/bs";
import { handleCopy } from "../../functions/formats";
import './styles/payDetail.css';
import CopyGB from "../../components/CopyGB";

interface PayDetailInt {
    type?: string;
    bank: string;
    num?: string;
    doc_type?: string;
    client_name: string;
    doc_num?: string;
    allowCopy?: boolean;
    alwaysWhite?: boolean
}

const PayDetail = ({ bank, client_name, doc_num, doc_type, num, type, allowCopy = true, alwaysWhite }: PayDetailInt) => (
    <div className="payments-account-detail-box">
        <PayDetailRow
            label='Titular:'
            value={client_name}
            allowCopy={allowCopy}
            alwaysWhite={alwaysWhite}
        />
        <PayDetailRow
            label='Banco:'
            value={bank}
            allowCopy={allowCopy}
            alwaysWhite={alwaysWhite}
        />
        {
            type && num && <PayDetailRow
                label={type === "wallet" ? "Número:" : type.toUpperCase() + ":"}
                value={num}
                allowCopy={allowCopy}
                alwaysWhite={alwaysWhite}
            />
        }
        {
            bank !== "PayPal" && doc_type && doc_num && <PayDetailRow
                label={`${doc_type}:`}
                value={doc_num}
                allowCopy={allowCopy}
                alwaysWhite={alwaysWhite}
            />
        }
    </div>
);

const PayDetailRow = ({ label, value, allowCopy, alwaysWhite = false }: { label: string, value: string, allowCopy: boolean, alwaysWhite?:boolean }) => (
    <div className="payment-detail-row" style={{backgroundColor: alwaysWhite ? "white" : undefined}}>
        <p>{label}</p>
        <div className="payment-detail-value">
            <p>{value}</p>
            {
                allowCopy && <CopyGB value={value} />
            }
        </div>
    </div>
)

export default PayDetail;