import { createContext, useContext, useEffect, useState } from "react";
import useDataService from "../services/data.service";
import { useAuth } from "./TokenContext";

interface DataPackageContext {
    personalInfo: personalInfoInt | undefined;
    beneficiaries: accountInt[] | undefined;
    orders: ordersInt[] | undefined;
    offers: OfferInt[] | undefined;
    setOffers: (param: OfferInt[] | undefined) => void;
    reqData: () => Promise<void>;
}

const DataPackageContext = createContext<DataPackageContext | null>(null);


interface DataPackageProps {
    children: React.ReactNode;
}

const DataPackageProvider: React.FC<DataPackageProps> = ({ children }) => {

    const { getDataPackage } = useDataService();
    const { token } = useAuth();

    const [personalInfo, setPersonalInfo] = useState<personalInfoInt | undefined>();
    const [beneficiaries, setBeneficiaries] = useState<accountInt[] | undefined>();
    const [orders, setOrders] = useState<ordersInt[] | undefined>();
    const [offers, setOffers] = useState<OfferInt[] | undefined>();

    const handleData = async () => {
        const data = await getDataPackage();
        if (data) {
            setPersonalInfo(data.personalInfo);
            setBeneficiaries(data.beneficiaries);
            setOrders(data.orders);
        }
    }

    const reqData = async () => {
        await handleData();
    }

    useEffect(() => {
        if (token) {
            handleData();
        };

    }, [token]);

    return (
        <DataPackageContext.Provider value={
            {
                personalInfo,
                beneficiaries,
                orders,
                offers,
                setOffers,
                reqData
            }
        }>
            {children}
        </DataPackageContext.Provider>
    );
}


const useDataPackage = (): DataPackageContext => {
    const context = useContext(DataPackageContext);
    if (!context) {
        throw new Error('useDataPackage debe ser utilizado dentro de DataPackageProvider');
    }
    return context;
};

export { DataPackageProvider, useDataPackage };

interface personalInfoInt {
    client_id: string;
    client_name: string;
    client_country: string;
    doc_type: string;
    whatsapp: number;
}

export interface accountInt {
    alias_id: number;
    alias_name: string;
    client_name: string;
    operation: string;
    doc_type: string;
    doc_num: string;
    account_id: number;
    account_country: string;
    account_bank: string;
    account_type: string;
    account_num: string;
    account_state: number;
}

export interface ordersInt{
    order_id: string;
    action_date: string;
    from_data: string;
    to_data: string;
    val_from: number;
    real_val_from: number;
    val_to: number;
    pending_val_to: number;
    order_state: string;
    beneficiary: number;
}

export interface completeOrderInt extends ordersInt {
    payments_from: paymentInt[];
    payments_to: paymentInt[];
    difference: number;
    beneficiary_account: accountInt;
}

export interface paymentInt extends accountInt {
    payment_id: number;
    action_date: string;
    currency: string;
    amount: number;
    payment_state: string;
    paid_date: string;
    alias_id_from: number;
    alias_id_to: number;
    order_id_from: string | null;
}

export interface OfferInt {
    id: number;
    action_date: string;
    from_data: string;
    val_from: number;
    real_val_from: number;
    offer_desc: number;
    order_id: string | null;
}