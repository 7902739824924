import colFlag from "../../assets/images/col.png";
import argFlag from "../../assets/images/arg.jpg";
import usaFlag from "../../assets/images/usa.jpg";
import { FaCheck } from "react-icons/fa6";
import { IoIosClose } from "react-icons/io";
import './styles/paymentTitle.css';

const PaymentTitle = ({ state, currency, index, length }: { state: string, currency: string, index: number, length: number }) => (
    <div className='payments-title-box'>
        {
            state === "completed" || state === "cancelled" ? (
                <div className={`payments-title-icon ${state === "completed" ? "payments-title-icon-green" : "payments-title-icon-red"}`}>
                    {
                        state === "completed" ? <FaCheck color='white' /> : <IoIosClose color='white' />
                    }
                </div>
            ) : (
                <img
                    alt='bandera'
                    src={currency === "COP" ? colFlag : currency === "ARS" ? argFlag : usaFlag}
                />
            )
        }
        <PaymentState state={state} />
        {
            length > 1 && (
                <p className='payments-title-index'>{index + 1} / {length}</p>
            )
        }
    </div>
);

const PaymentState = ({ state }: { state: string }) => (
    <p>
        <b>
            {
                state === 'pending'
                    ? "Datos de Pago"
                    : state === 'pending2'
                        ? "Enviando"
                        : state === "paid"
                            ? "Pagado"
                            : state === "validated"
                                ? "Pago Confirmado"
                                : state === "completed"
                                    ? "Pago Completado"
                                    : "Pago Cancelado"
            }
        </b>
    </p>
);

export default PaymentTitle