interface BackgroundsInt {
    title?: string;
    color: string;
    children?: React.ReactNode;
}

const Backgrounds = ({ color, title, children }: BackgroundsInt) => {
    return (
        <div style={{
            backgroundColor: color,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
        >
            {
                title && <h2 className="section-title-gb">{title}</h2>
            }
            {children}
        </div>
    )
}

export default Backgrounds;
