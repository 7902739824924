import logo from "../assets/images/logo-white.png";
import { FaFacebook } from "react-icons/fa6";
import { SlSocialInstagram } from "react-icons/sl";
import { TiSocialYoutube } from "react-icons/ti";
import "./styles/footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer>
            <p className="footer-appdland">By AppDland</p>
            <div className="footer-upper">
                <img
                    src={logo}
                    alt="logo colcambios en el pie de pagina"
                    className="footer-logo"
                />
                <FooterLinks
                    links={[
                        { label: 'Precios', path: '/precios' },
                        { label: 'Preguntas Frecuentes', path: '/preguntas' }
                    ]}
                />
                {/* <div className="footer-upper-sec">
                    <p onClick={() => navigate('/tyc')}>Términos y Condiciones</p>
                    <p onClick={() => navigate('/legal')}>Legales</p>
                    <p onClick={() => navigate('/precios')}>Precios</p>
                </div>
                <div className="footer-upper-sec">
                    <p>Contacto</p>
                    <p onClick={() => navigate('/prf')}>Preguntas Frecuentes</p>
                    <p>Quienes Somos</p>
                </div> */}
                {/* <div className="footer-upper-sec">
                    <p>Reclamos</p>
                    <p>Otros Servicios</p>
                    <p>Unirse al Equipo</p>
                </div> */}
            </div>
            <div className="footer-down">
                <h3>Encuentranos</h3>
                <div className="footer-down-logos">
                    <Navigator href="https://www.facebook.com/people/ColCambios/100090280403428/?mibextid=LQQJ4d">
                        <FaFacebook size={window.innerWidth > 600 ? 40 : 30} cursor="pointer" />
                    </Navigator>
                    <Navigator href="https://www.instagram.com/colcambios_oficial">
                        <SlSocialInstagram size={window.innerWidth > 600 ? 40 : 30} cursor="pointer" />
                    </Navigator>
                    <Navigator href="https://www.youtube.com/@colcambios4600">
                        <TiSocialYoutube size={window.innerWidth > 600 ? 40 : 30} cursor="pointer" />
                    </Navigator>
                </div>
                <p>Todos los derechos reservados © ColCambios</p>
                <p>Colombia +57 321 268 6456 | ColCambios</p>
            </div>

        </footer>
    )
}

interface FooterLinksInt {
    links: { label: string, path: string }[];
}
const FooterLinks = ({ links }: FooterLinksInt) => {
    const navigate = useNavigate();

    return (
        <div className="footer-upper-sec">
            {
                links.map((link, index) => (
                    <p onClick={() => navigate(link.path)} key={index}>{link.label}</p>
                ))
            }
        </div>
    )
}

const Navigator = ({ children, href }: { children: React.ReactNode, href: string }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "inherit" }}>{children}</a>
)

export default Footer;