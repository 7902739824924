import InputGB from "../../components/InputGB";
import { useState } from "react";
import { useFormApp } from "appdland-ui";
import TitleGB from "../../components/TitleGB";
import DecorationMessage from "./DecorationMessage";
import FirstCall from "./FirstCall";
import VerifyCode from "./VerifyCode";
import ChangePass from "./ChangePass";
import { useNavigate } from "react-router-dom";
import useLoginService from "../../services/login.service";
import { usePopUp } from "../../context/PopUp.context";
import "./styles/forgot.css";

const Forgot = () => {

    const [forgotState, setForgotState] = useState(0);
    const navigate = useNavigate();
    const { register, setForm, formValues, validateForm } = useFormApp();
    const { forgotFirstCall } = useLoginService();
    const { setPopUp } = usePopUp();


    const sendCode = () => {
        forgotFirstCall(formValues.user)
            .then(() => {
                setForgotState(1);
            })
            .catch((error) => {
                if (error.title === 'forgotInit') {
                    setForm('user', formValues.user, true, 'No parece tu documento');
                }
            })
    }

    const validateCodeSuccess = () => {
        setForgotState(2);
    }

    const chnagePassSuccess = () => {
        setForgotState(0);
        navigate('/ingresar');
        setPopUp({
            title: 'Logrado',
            content: <p>Has cambiado tu contraseña correctamente, ahora puedes iniciar sesión con tu nueva contraseña</p>
        })
    }

    const resetProcess = () => {
        setForgotState(0);
        setForm('user', '');
    }

    return (
        <div className="forgot-background">
            <TitleGB>Olvidé Mi Contraseña</TitleGB>
            <div className="forgot-big-box">
                <DecorationMessage />
                <div className="forgot-box">
                    <h3>Recuperar</h3>
                    <br />
                    {
                        forgotState === 0 ? (
                            <FirstCall
                                sendCode={sendCode}
                                validateForm={validateForm}
                                input={
                                    <InputGB
                                        {...register('user', { min: 4, max: 15, errorEvents: { onMinError: 'No parece tu documento', onMaxError: 'No parece tu documento', onRequiredError: 'Ingresa tu documento' } })}
                                        type="text"
                                        placeholder="Número de Documento"
                                        errorOnPlaceholder
                                    />
                                }
                            />
                        ) : forgotState === 1 ? (
                            <VerifyCode
                                user={formValues.user}
                                onSuccess={validateCodeSuccess}
                                onError={resetProcess}
                            />
                        ) : forgotState === 2 ? (
                            <ChangePass
                                user={formValues.user}
                                onSuccess={chnagePassSuccess}
                                onError={resetProcess}
                            />
                        ) : null
                    }
                </div>
                <br />
            </div>

        </div>
    )
}

export default Forgot;