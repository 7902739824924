import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/TokenContext';

export const ProtectedRoute = () => {

    const { token } = useAuth();

    if (!token) {
        return <Navigate to="/ingresar" />
    }
    return <Outlet />
}