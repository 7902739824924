import { InputApp, InputAppProps } from "appdland-ui";

const InputGB = (props: InputAppProps) => {

    return (
        <InputApp 
            {...props}
            style={{
                type: "bottom-line",
                textAlign: "center"
            }}
            errorOnPlaceholder
        />
    )
}

export default InputGB;