import { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDataPackage } from "../context/DataPackage.context";
import Changer from "../components/Changer";
import { useChange } from "../context/Change.context";
import DestDetail from "./DestDetail";
import useOrdersService from "../services/orders.service";
import { useChanges } from "../context/Changes.context";
import { Loading } from "../components/Loading";

const SendDirect = () => {

    const { benef: benef_id } = useParams<{ benef: string }>();
    const navigate = useNavigate();

    const { beneficiaries } = useDataPackage();
    const { limitMin, setFrom, setFromOp, setToOp, fromOp, toOp, from, to, setterLimitMin, currentSide} = useChange();
    const { changes } = useChanges();
    const { send } = useOrdersService();

    const [benefName, setBenefName] = useState("");
    const [idealOp, setIdealOp] = useState<string>();
    const standarVal = limitMin.number * 5;


    useEffect(() => {
        if (benef_id && isNaN(Number(benef_id)) === false && changes) {
            const benefData = beneficiaries?.find(({ alias_id }) => alias_id === Number(benef_id));
            if (benefData) {
                const toOperation = benefData.account_country === "COL"
                    ? benefData.account_bank + "COP"
                    : "TransferenciaArgentinaARS"

                const [ideal] = changes.filter(({ currency_get }) => currency_get === toOperation).map(({ currency_from }) => currency_from)
                setFromOp(ideal);
                setIdealOp(ideal);
                setFrom(standarVal);
                setToOp(toOperation);
                setBenefName(benefData.alias_name);
            }
        } else {
            navigate("../../destinatarios");
        }
    }, [benef_id, beneficiaries, standarVal, changes]);

    const handleSendDest = async () => {
        if (benef_id) {
            if (limitMin.number > from) {
                setterLimitMin(true);
                return
            }

            send({
                from_data: fromOp,
                to_data: toOp,
                val_from: from,
                val_to: to,
                beneficiary: Number(benef_id),
                operation: currentSide
            });
        }

    }

    useEffect(() => {
        document.title = `Envío para ${benefName}`;
    }, [benefName]);

    return (
        benef_id &&
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2 className="section-title-gb">Enviar</h2>
            <Suspense fallback={<Loading />}>
                {
                    idealOp && (
                        <>
                            <p style={{ color: "gray" }}>Recibirá {benefName}</p>
                            <p style={{ color: "gray", marginBottom: "20px", fontStyle: "italic", fontSize: "small" }}>¿Cuánto vas a enviar?</p>
                            <Changer half />
                            <br />
                            <DestDetail innerBenef={Number(benef_id)} send={handleSendDest} />
                        </>
                    )
                }
            </Suspense>
        </div>
    )
}

export default SendDirect;