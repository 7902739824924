import { ThemeProvider } from '@emotion/react';
import { TextField, createTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, Ref, forwardRef, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import "./styles/inputmoney.css";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#292929',
        },
        // neutral: {
        //     main: '#292929',
        //     contrastText: '#292929',
        // },
        secondary: {
            // This is green.A700 as hex.
            main: '#292929',
        },
    }
});

interface NumericFormatCustomProps {
    name?: string;
    onChange: (event?: any) => void;
    decimalScale: number
}

const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props: NumericFormatCustomProps,
    ref: Ref<HTMLInputElement>
) {
    const { onChange, ...other } = props;

    const handleKeyDown = (event: any) => {
        if (event.target) {
            const { selectionStart, selectionEnd } = event.target;
            if (selectionStart === 2 && selectionStart === selectionEnd) {
                event.target.setSelectionRange(3, 3);
            }
        }
    }

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$ "
            decimalScale={props.decimalScale}
            // fixedDecimalScale
            onKeyDown={handleKeyDown}
            allowNegative={false}
            inputMode='decimal'
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

interface InputMoneyGBProps {
    label?: string;
    value: any;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    name: any;
    required?: boolean;
    ifError?: any;
    onFocus?: (event: any) => void;
    textAlign?: "left" | "center";
    onEnterKey?: () => void;
    decimal?: number;
}

const InputMoneyGB = ({ label, value, onChange, name, required, ifError, onFocus, textAlign = "left", onEnterKey, decimal = 0 }: InputMoneyGBProps) => {

    const handleFocus = (event: any) => {
        // Ejecutar la función onFocus, si existe
        innerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        if (onFocus) {
            onFocus(event);
        }
    }


    const innerRef = useRef<HTMLDivElement>(null);

    const isSafari = () => {
        const ua = navigator.userAgent;
        const isSafari = /Safari/.test(ua) && !/Chrome/.test(ua);
        return isSafari;
    };

    const handleEnter = (event: any) => {
        if (event.key === 'Enter') {
            if (onEnterKey) {
                onEnterKey();
            }
        }

        if(isSafari()){
            if (event.code === "Comma" || event.keyCode === 188 || event.key === ",") {
                //IMPACTAR UN DECIMAL EN IOS
            }
        }
    }

    return (
        <div className='global-interaction-box' ref={innerRef}>
            <ThemeProvider theme={theme}>
                <TextField
                    label={label}
                    value={value}
                    onChange={onChange}
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        inputProps: { decimalScale: decimal, style: { fontSize: "large", textAlign } }
                    }}
                    name={name}
                    required={required}
                    error={ifError}
                    margin='dense'
                    fullWidth={true}
                    autoComplete="off"
                    size="small"
                    variant="standard"
                    inputProps={{ style: { fontSize: "large", textAlign } }}
                    InputLabelProps={{ style: { fontSize: "large" } }}
                    onFocus={handleFocus}
                    onKeyDown={handleEnter}
                    inputMode='decimal'
                />
            </ThemeProvider>
        </div>
    );
};

export default InputMoneyGB;