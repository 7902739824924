import { useState } from "react";
import "./styles/burgermenu.css";
interface BurgerMenuInt {
    handleAction: () => void;
    status: boolean;
    setStatus: (param: boolean) => void;
}
const BurgerMenu = ({handleAction, status, setStatus}:BurgerMenuInt) => {

    const handleToggle = () => {
        setStatus(!status);
        handleAction();
    }
    return (
        <div className="burger-container" onClick={handleToggle}>
            <div className={`burger-container-line ${status ? "burger-container-line-active" : ""}`} />
            <div className={`burger-container-line ${status ? "burger-container-line-active" : ""}`} />
            <div className={`burger-container-line ${status ? "burger-container-line-active" : ""}`} />
            <div className={`burger-container-line ${status ? "burger-container-line-active" : ""}`} />
        </div>
    )
}

export default BurgerMenu;