import { createContext, useContext, useEffect, useState } from "react";
import PopUpGB from "../components/PopUpGB";

interface PopUpInfoInt {
    content: React.ReactNode;
    title?: string;
    confirmButton?: React.ReactNode;
    onClose?: () => void;
}

interface PopUpContextInt {
    setState: (state: boolean) => void;
    setPopUp: (content: PopUpInfoInt | undefined) => void;
}

const PopUpContext = createContext<PopUpContextInt | null>(null);

interface PopUpProviderProps {
    children: React.ReactNode;
}

const PopUpProvider: React.FC<PopUpProviderProps> = ({ children }) => {
    const [state, setState] = useState<boolean>(false);
    const [content, setPopUp] = useState<PopUpInfoInt>();

    useEffect(() => {
        if (state) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
            setPopUp(undefined);
        }
    }, [state]);

    useEffect(() => {
        if (content) {
            setState(true);
        }
    }, [content]);

    return (
        <PopUpContext.Provider value={{ setPopUp, setState }}>
            {
                state && (
                    <PopUpGB
                        setState={setState}
                        title={content?.title}
                        confirmButton={content?.confirmButton}
                        onClose={content?.onClose}
                    >
                        {content?.content}
                    </PopUpGB>
                )
            }
            {children}
        </PopUpContext.Provider>
    );
};

const usePopUp = (): PopUpContextInt => {
    const context = useContext(PopUpContext);
    if (!context) {
        throw new Error('usePopUpContext debe ser utilizado dentro de PopUpProvider');
    }
    return context;
};

export { PopUpProvider, usePopUp };