import * as Flags from "react-flags-select";
import { countries } from "../data/countries";
import { useEffect, useRef, useState } from "react";
import { useCountry } from "../context/Country.conext";
import "./styles/flags.css";
import { IoMdArrowDropdown } from "react-icons/io";

interface FlagsCompInt {
    Flag: any;
    name: string;
    primary: string;
    code: string;
}

interface FlagSelectInt {
    // value: string;
    onChange: (param: number) => void;
}

const FlagSelect = ({ onChange }: FlagSelectInt) => {

    const [flags, setFlags] = useState<FlagsCompInt[]>();
    const [Selected, setSelected] = useState<FlagsCompInt>();
    const [list, setList] = useState(false);
    const [mostUsed, setMostUsed] = useState<FlagsCompInt[]>();
    const listRef = useRef<HTMLDivElement>(null);
    const findRef = useRef<HTMLDivElement>(null);
    const [find, setFind] = useState("");
    const [found, setFound] = useState<FlagsCompInt[]>([])

    const { country } = useCountry();

    useEffect(() => {
        const most: FlagsCompInt[] = [];
        if (flags) {
            flags.map(flag => {
                if (flag.name === "CO" || flag.name === "AR" || flag.name === "US") {
                    most.push(flag);
                }
            })
        }
        setMostUsed(most);
    }, [flags]);

    useEffect(() => {
        const FlagsComponents = Object.values(Flags);
        FlagsComponents.pop();
        const FlagsKeys = Object.keys(Flags);
        FlagsKeys.pop();
        const FlagsComp: FlagsCompInt[] = [];
        FlagsComponents.map((value, index) => {
            const country = countries[FlagsKeys[index].toUpperCase()];
            FlagsComp.push({
                Flag: value as any,
                name: FlagsKeys[index].toUpperCase(),
                primary: country.primary,
                code: country.secondary
            });

        });
        setFlags(FlagsComp);
        setFound(FlagsComp);
    }, []);

    useEffect(() => {
        if (flags && !Selected) {
            setter(country);
            onChange(country === "CO" ? 57 : country === "AR" ? 54 : 1);
        }
    }, [country, flags, Selected]);

    useEffect(() => {
        const contain = ({ target }: any) => {
            if (!listRef.current?.contains(target)) {
                setList(false);
            }
        }

        document.addEventListener("click", contain, true);
        return () => { window.removeEventListener("click", contain, true) }
    }, []);

    const setter = (value: string) => {
        if (flags) {
            const FlagInfo = flags.find((info) => info.name === value);
            if (FlagInfo) {
                setSelected(FlagInfo);
            }
        }

    }

    const handleSelect = (value: string, code: string) => {
        setter(value);
        const codeOff = Number(code.slice(1));
        onChange(codeOff);
        setList(false);
        setFind("");
        setFound(flags ? flags : found);
    }

    const handleFind = (value: string) => {
        if (flags) {
            const found = flags.filter((info) => info.primary.toLowerCase().includes(value.toLowerCase()));
            setFound(found);
            setFind(value);
        }
    }

    const handleFocus = () => {
        if (findRef.current) {
            findRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'center',
                inline: "center"
            })
        }
    }

    return (
        <div className="flag-container" ref={listRef}>
            <div className="flag-select" onClick={() => setList(!list)}>
                <div className="flag-selected">
                    {
                        Selected && (
                            <Selected.Flag width={30} height={30} style={{ position: "absolute" }} />
                        )
                    }
                </div>
                <IoMdArrowDropdown size={20} color="gray" />
            </div>
            {
                list && (
                    <div className="flag-options" >
                        <div className="flag-most-separator">
                            <p>Selecciona el código de tu país</p>
                        </div>
                        {
                            mostUsed && mostUsed.map(({ Flag, name, primary, code }, index) => (
                                <div className="flag-options-option" key={index} onClick={() => handleSelect(name, code)}>
                                    <div className="flag-selected">
                                        <Flag
                                            width={30}
                                            height={30}
                                            style={{ position: "absolute" }}
                                        />
                                    </div>
                                    <p>{primary} <b>{code}</b></p>
                                </div>
                            ))
                        }
                        <div className="flag-most-separator" ref={findRef}>
                            <input
                                type="text"
                                value={find}
                                onChange={({ target }) => handleFind(target.value)}
                                autoComplete="new-password"
                                placeholder="Buscar País"
                                onFocus={handleFocus}
                                onClick={e => e.preventDefault()}
                            />
                        </div>
                        {
                            found && found.map(({ Flag, name, primary, code }, index) => (
                                <div className="flag-options-option" key={index} onClick={() => handleSelect(name, code)}>
                                    <div className="flag-selected">
                                        <Flag
                                            width={30}
                                            height={30}
                                            style={{ position: "absolute" }}
                                        />
                                    </div>
                                    <p>{primary} <b>{code}</b></p>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    )
}

interface ComponentInt {

}

{/* <ReactFlagsSelect
    selected={selected}
    onSelect={(code) => setSelected(code)}
    searchable
    searchPlaceholder="Búscar"
    showSelectedLabel={false}
    showSecondarySelectedLabel={false}
    customLabels={countries}
    selectedSize={15}
    optionsSize={10}
    className="flag-select"
    cla

/> */}

export default FlagSelect;