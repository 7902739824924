import { BsCopy } from "react-icons/bs";
import { handleCopy } from "../functions/formats";
import { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import "./styles/copygb.css";

interface CopyGBInt {
    /**
     * @value the value that should be copied
     */
    value: string;
}

const CopyGB = ({ value }: CopyGBInt) => {

    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        if (clicked === false) {
            handleCopy(value);
            setClicked(true);
            setTimeout(() => {
                setClicked(false);
            }, 2000);
        }
    }

    return (
        <div onClick={handleClick} className="copygb-container">
            {
                clicked ? (
                    <div className="copygb-checked">
                        <p>Copiado</p>
                        <FaCheck />
                    </div>
                ) : (
                    <BsCopy cursor="pointer" />
                )
            }
        </div>
    )
}

export default CopyGB;