import { InputAppProps } from "appdland-ui";
import FlagSelect from "../../components/Flag";
import InputGB from "../../components/InputGB";

interface WhatsAppInt extends Omit<InputAppProps, 'type' | 'placeholder' | 'onBlur' | 'child' | 'childSize'> {
    whatsapp: string;
    setForm: (arg: string, arg2: string, arg3?: boolean, arg4?: string) => void;
    country: number;
    setCountry: (param: number) => void;
}

export const WhatsAppInputRegisterConfig = {
    min: 5,
    max: 15,
    type: 'number',
    errorEvents: {
        onRequiredError: "Ingresa tu WhatsApp",
        onMinError: "No Parece ser un WhatsApp"
    }
}

const WhatsAppInput = ({ whatsapp, setForm, country: cont, setCountry, ...props }: WhatsAppInt) => {



    const handleValidateWp = () => {
        const country = String(cont);
        const wp = String(whatsapp);

        let validator = false;
        for (let index = 0; index < country.length; index++) {
            if (country[index] === wp[index]) {
                validator = true;
            } else {
                validator = false;
                break;
            }
        }

        if (validator) {
            setForm('whatsapp', whatsapp, true, `No es necesario agregar ${country}`);
        }
    }

    return (
        <InputGB
            {...props}
            type="tel"
            placeholder="Número de WhatsApp"
            onBlur={handleValidateWp}
            child={
                <FlagSelect
                    onChange={setCountry}
                />
            }
            childSize={60}
        />
    )
}

export default WhatsAppInput;