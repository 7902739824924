import axios from "axios";
import useServices from "./services.service";

const useDataService = () => {
    const { buildHeader, manageError } = useServices();

    const getDataPackage = async () => {
        try {
            const { data, headers } = await axios.get(process.env.REACT_APP_CLIENT_DATA || "", {
                timeout: 5000,
                headers: buildHeader()
            });
            return data;
        } catch (error) {
            manageError(error);
        }
    }


    return {
        getDataPackage
    }
}

export default useDataService;