import { IoMdArrowDropdown } from "react-icons/io";
import InputMoneyGB from "./InputMoneyGB";
import { useEffect, useRef, useState } from "react";
import { useChange } from "../context/Change.context";
import "./styles/changercomplex.css";

interface optionsInt {
    value: string;
    label: string;
    image: string;
}

interface limitInt {
    state: boolean;
    message: string;
}

interface ChangerComplexInt {
    title: "Envías" | "Recibes";
    options: optionsInt[];
    value: string;
    setvalue: (param: string) => void;
    inputValue: number;
    setInputValue: (param: number) => void;
    side: "left" | "right";
    limit: limitInt;
    defaultValue?: optionsInt;
    hideInput?: boolean;
    decimal?: number
}
const ChangerComplex = ({ title, options, value, setvalue, inputValue, setInputValue, side, limit, defaultValue, hideInput = false, decimal = 0 }: ChangerComplexInt) => {


    const [showList, setShowList] = useState(false);
    const { block, setCurrentSide, setterLimitMin } = useChange();
    const selectRef = useRef<HTMLDivElement | null>(null);
    const listRef = useRef<HTMLDivElement | null>(null);
    const [innerVal, setInnerVal] = useState<optionsInt>();

    useEffect(() => {
        const killer = ({ target }: any) => {
            if (!selectRef.current?.contains(target)) {
                setShowList(false);
                document.body.style.overflow = 'auto';
                document.body.style.height = "auto";
            }
        }
        window.addEventListener("click", killer, true);
        return () => { window.removeEventListener("click", killer, true) }
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setInnerVal(defaultValue);
        } else {
            setInnerVal(options[0]);
        }
    }, [defaultValue]);

    const handleLimit = (e: any) => {
        e.stopPropagation();
    }

    useEffect(() => {

        if (limit.state === true) {
            selectRef.current?.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }


    }, [limit.state]);

    const handleClick = () => {
        if ("vibrate" in navigator) {
            navigator.vibrate(30);
        }
        setShowList(true);
        document.body.style.overflowY = 'hidden';
        // document.body.style.touchAction = "none";
        document.body.style.height = "100dvh";
        listRef.current?.scrollIntoView();
    }

    const optionClick = (value: string) => {
        if ("vibrate" in navigator) {
            navigator.vibrate(30);
        }
        setvalue(value);
        document.body.style.overflow = 'auto';
        document.body.style.height = "auto";
    }

    return (
        <>
            <div className="changerCX-container" ref={selectRef} onClick={handleClick}>
                {
                    hideInput === false && (
                        <p className="changerCX-title" onClick={handleLimit} style={{
                            borderColor: limit.state ? "red" : "white",
                            color: limit.state ? "red" : "black",
                            fontWeight: limit.state ? "normal" : "400"
                        }}>
                            {
                                limit.state ? limit.message : title
                            }
                        </p>
                    )
                }

                {
                    innerVal && (
                        <div className="changerCX-content" style={{
                            alignItems: hideInput ? 'center' : undefined,
                            margin: hideInput ? '0' : undefined,
                            minWidth: hideInput ? '300px' : undefined,
                            justifyContent: hideInput ? 'space-evenly' : undefined
                        }}>
                            <img
                                src={innerVal.image}
                                className="changerCX-content-selected-img"
                            />
                            <div className="changerCX-content-main" style={{
                                justifyContent: hideInput ? 'center' : undefined
                            }}>
                                <p>{innerVal.label}</p>
                                {
                                    hideInput === false && (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            {
                                                block ? (
                                                    <p>Selecciona otra opción</p>
                                                ) : (
                                                    <InputMoneyGB
                                                        name="input-changer-left"
                                                        value={inputValue}
                                                        onFocus={() => { setCurrentSide(side); setterLimitMin(false); }}
                                                        onChange={({ target }) => setInputValue(Number(target.value))}
                                                        textAlign="center"
                                                        decimal={decimal}
                                                    />
                                                )
                                            }

                                        </div>
                                    )
                                }
                            </div>
                            <IoMdArrowDropdown size={30} color="#004E77" style={{
                                rotate: showList ? "180deg" : "0deg",
                                marginTop: "-4"
                            }} />
                        </div>
                    )
                }
            </div>
            <div
                className="changerCX-list-container"
                style={{
                    height: showList ? '100%' : '0px'
                }}
                ref={listRef}
            >
                <div
                    className="changerCX-list"
                    style={{
                        height: showList ? `${options.length * 80}px` : "0px"
                    }}
                >
                    {
                        options.map(({ value, label, image }, index) => (
                            <div key={index} className="changerCX-list-option" onClick={() => optionClick(value)}>
                                <img
                                    src={image}
                                />
                                <p><b>{label}</b> {`(${value.slice(-3)})`}</p>
                            </div>
                        ))
                    }

                </div>
            </div>
        </>
    )
}
export default ChangerComplex;