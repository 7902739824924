import { useDataPackage } from "../../context/DataPackage.context";
import { CiEdit } from "react-icons/ci";
import NotReady from "../../components/NotReady";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { usePopUp } from "../../context/PopUp.context";
import useLogOutService from "../../services/logout.service";
import ButtonGB from "../../components/ButtonGB";
import { RxExit } from "react-icons/rx";
import "./styles/user.css";

const User = () => {
    const { setPopUp, setState } = usePopUp();
    const { logOut } = useLogOutService();

    const { personalInfo } = useDataPackage();

    useEffect(() => {
        document.title = "Perfil | ColCambios";
    }, []);

    const location = useLocation();

    const handleLogout = () => {
        setPopUp({
            title: "¿Deseas Salir?",
            content: 'Deberás ingresar tu número de documento y contraseña para inciar sesión nuevamente',
            confirmButton: <ButtonGB title='Salir' onClick={() => { logOut(); setState(false) }} />
        })
    }

    return (
        location.pathname === "/perfil/mis-datos" ? (
            <section className="profile-user">
                <h2 className="profile-user-title">Mi Perfil</h2>
                {
                    personalInfo ? (
                        <div className="profile-user-info-container">
                            <Box
                                info={[{ title: "Nombre Completo", info: personalInfo.client_name }]}
                            />
                            <Box
                                info={[{ title: "Número de WhatsApp", info: `+${personalInfo.whatsapp}` }]}
                                icon
                            />
                            <Box
                                info={[
                                    { title: "País de Residencia", info: personalInfo.client_country === "COL" ? "Colombia" : personalInfo.client_country === "ARG" ? "Argentina" : "Otro" },
                                    { title: "Tipo de Documento", info: personalInfo.doc_type },
                                    { title: "Número de Documento", info: personalInfo.client_id }
                                ]}
                            />
                            <Box
                                info={[{ title: "Contraseña", info: "********" }]}
                                icon
                            />
                            <div className="logout-button" onClick={handleLogout}>
                                <RxExit />
                                <p>Salir</p>
                            </div>
                        </div>
                    ) : (
                        <NotReady />
                    )
                }

            </section>
        ) : (<Outlet />)
    )
}

interface BoxInfoInt {
    title: string;
    info: string;
}

interface BoxInt {
    info: BoxInfoInt[];
    icon?: boolean;
}
const Box = ({ info, icon = false }: BoxInt) => {

    const navigate = useNavigate();

    return (
        <div className="profile-user-box">
            {
                info.map(({ title, info }, index) => (
                    <div key={index} className="profile-user-box-row">
                        <h3>{title}</h3>
                        <div className="profile-user-box-row-inner">
                            <p>{info}</p>
                            {
                                icon && (
                                    <div onClick={() => title === "Número de WhatsApp"
                                        ? navigate("cambiar-whatsapp")
                                        : navigate("cambiar-contraseña")
                                    }>
                                        <CiEdit size={25} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default User;