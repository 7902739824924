import { IoAdd } from "react-icons/io5";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { accountInt, useDataPackage } from "../context/DataPackage.context";
import useAccountsService from "../services/accounts.service";
import ButtonGB from '../components/ButtonGB';
import { useEffect, useRef, useState } from "react";
import { IoIosArrowUp } from "react-icons/io";
import { useInputGroup } from "../custom/useInput";
import { formatSeparate, formatUpper } from '../functions/formats';
import { MdDeleteForever } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import "./styles/benef.css";
import TitleGB from "../components/TitleGB";

const Benef = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { beneficiaries } = useDataPackage();
    const [editMode, setEditMode] = useState(false);
    const [formVals, setFormVals] = useInputGroup([
        "alias_name",
        "_doc_num"
    ]);


    return (

        location.pathname === "/perfil/destinatarios" ? (
            <section>
                <TitleGB>Mis Destinatarios</TitleGB>
                {/* <div onClick={() => navigate("crear")} className="benef-create">
                    <IoAdd size={20} color="white" />
                    <p>Agregar Destinatario (próximamente)</p>
                </div> */}
                <p className="benef-introduction">¿A quién le enviarás hoy? Puedes seleccionar un destinatario al que le hayas enviado antes</p>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {
                        beneficiaries && (
                            beneficiaries.length > 0 ? (
                                beneficiaries.map((info, index) => (
                                    <BenefInfo key={index} accountInfo={info} />
                                ))
                            ) : (
                                <p style={{ margin: "50px 0", opacity: 0.5 }}>Aún no tienes destinatarios</p>
                            )
                        )
                    }
                </div>
            </section>

        ) : (
            <Outlet />
        )

    )
}

interface BenefInfoInt {
    accountInfo: accountInt
}

const BenefInfo = ({ accountInfo }: BenefInfoInt) => {
    const { deleteAcc, editAcc } = useAccountsService();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);


    const handleDelet = () => {
        deleteAcc({
            alias_id: accountInfo.alias_id,
            account_id: accountInfo.account_id
        }).then(() => {
            console.log('eliminado');
        }).catch(error => {
            console.log(error);
            navigate('/error')
        });
    };

    const handleSend = () => {
        navigate(`./enviar-directo/${accountInfo.alias_id}`);
    }

    const handleEditMode = () => {
        // if (state === true) {
        //     if (Array.isArray(beneficiaries) && index >= 0 && index < beneficiaries.length) {
        //         setFormVals(beneficiaries[index].alias_name, 'alias_name', false);
        //     }
        // }
        // setEditMode(state)
    }

    const containerRef = useRef<HTMLDivElement>(null);


    const toggleActive = () => {
        const element = containerRef.current;
        if (element) {
            if (show) {
                element.style.height = window.innerWidth > 600 ? "65px" : "48px";
                setShow(false);
            } else {
                element.style.height = window.innerWidth > 600 || window.innerWidth < 350 ? "300px" : "240px";
                setShow(true);
            }
        }
    };

    useEffect(() => {
        document.title = "Destinatarios | ColCambios";
    }, []);

    return (
        <div className="benef-box-container" ref={containerRef}>

            <div className={`benef-box-upper ${show && "benef-box-upper-active"}`} onClick={toggleActive}>
                <p className="benef-box-title">{accountInfo.alias_name.split(" ")[0]}</p>
                <p>{accountInfo.account_country}</p>
            </div>

            <div>
                <div className="benef-box-lower">
                    <p>
                        {formatSeparate(accountInfo.account_bank)}
                        {
                            accountInfo.account_type !== "wallet" && (
                                <> - {accountInfo.account_type.toUpperCase()}</>
                            )
                        }
                    </p>
                    <p>{accountInfo.account_num}</p>
                </div>
                <div className="benef-box-lower">
                    <p>{accountInfo.client_name}</p>
                    {
                        accountInfo.account_bank !== "PayPal" && <p>{accountInfo.doc_type} {accountInfo.doc_num}</p>
                    }
                </div>
                <div className="benef-box-buttons">
                    {
                        process.env.NODE_ENV === "development" && (
                            <div className="benef-box-icons">
                                <ButtonGB
                                    onClick={handleDelet}
                                    icon={{
                                        icon: <MdDeleteForever size={20} color="red" />,
                                        size: 30
                                    }}
                                    style={{
                                        backgroundColor: "transparent"
                                    }}
                                    disabled
                                />
                                <ButtonGB
                                    onClick={handleEditMode}
                                    icon={{
                                        icon: <MdEdit size={20} color="black" />,
                                        size: 30
                                    }}
                                    style={{
                                        backgroundColor: "transparent"
                                    }}
                                    disabled
                                />
                            </div>
                        )
                    }
                    <ButtonGB
                        title="Enviar"
                        onClick={handleSend}
                    />
                </div>
            </div>

            <div className="benef-box-arrow" onClick={toggleActive}>
                <IoIosArrowUp size={20} style={{ rotate: show ? "initial" : "180deg" }} color="#004E77" />
            </div>
        </div>
    )
}

export default Benef;