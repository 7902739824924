import TitleGB from "../components/TitleGB";
import Footer from "../modules/Footer";
import Header from "../modules/Header";
import "./styles/legal.css";

const Legal = () => {

    return (
        <>
            <Header />
            <section>
                <TitleGB>Operatoria y legalidad de ColCambios</TitleGB>
                <p>Según la cámara de comercio de Bogotá existen ciertos requisitos, estos son los más importantes y con los que cuenta ColCambios según su rubro:</p>
                <ul>
                    <li>
                        <p>Registro en Cámara de Comercio:</p>
                        <br />
                        <p>Este registro genera una matricula vigente que se actualiza anualmente para que se mantenga <b>ACTIVA</b>, ColCambios cuanta con esta matricula. Se puede comprobar entrando a la página oficial de la <Link value="Cámara de Comercio de Bogotá" url="https://www" /> en Tramites y consultas, buscando como nombre ColCambios, aparecerá que estamos activos e inscritos en Bogotá con el <b>N° de matricula 0003574212</b>.</p>
                    </li>
                </ul>
                <div>
                    <h3>Registro Mercantil</h3>
                    <Item label="Numero de Matricula" value="2452789" />
                    <Item label="Ultimo Año Renovado" value="2024" />
                    <Item label="Fecha de Renovación" value="31 de Marzo de 2024" />
                </div>
                <p>
                    Este registro genera una matricula vigente que se actualiza anualmente para que se mantenga ACTIVA, ColCambios cuanta con esta matricula. Se puede comprobar entrando a la página oficial de la Cámara de Comercio de Bogotá en Tramites y consultas, buscando como nombre ColCambios, aparecerá que estamos activos e inscritos en Bogotá con el N° de matricula 0003574212.
                </p>
            </section>
            <Footer />
        </>
    )
}

const Link = ({ value, url }: { value: string, url: string }) => (
    <a href={url} target="_blank" className="legal-link">{value}</a>
)

const Item = ({ label, value }: { label: string, value: string }) => (
    <li>
        <p>{label}</p>
        <p>{value}</p>
    </li>
)

export default Legal;