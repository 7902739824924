import TitleGB from "../components/TitleGB";
import Footer from "../modules/Footer";
import Header from "../modules/Header";

const Tyc = () => {

    return (
        <>
            <Header />
            <section>
                <TitleGB>Términos y Condiciones de ColCambios</TitleGB>
            </section>
            <Footer />
        </>
    )
}

export default Tyc;