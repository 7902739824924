import { FormApp } from "appdland-ui";
import ButtonGB from "../components/ButtonGB";
import InputGB from "../components/InputGB";
import SelectGB from "../components/SelectGB";
import useSend from "../custom/useSend";
import { accTypes } from "../data/accTypes";
import { bankTypes } from "../data/bankTypes";
import { docTypes } from "../data/docTypes";
import "./styles/destForm.css";

const DestForm = ({ offerId }: { offerId?: number }) => {

    const {
        country,
        docType,
        handleSend,
        toOp,
        register,
        handleValidateForm
    } = useSend();

    return (
        <FormApp onSubmit={() => handleSend(offerId)} validateForm={handleValidateForm} className="send-form-container">
            <h3>Destinatario</h3>
            {/* fijo */}
            <div className="send-form-interaction">
                <InputGB
                    {...register('client_name', { min: 8, max: 200, errorEvents: { onMinError: "No parece un nombre", onMaxError: "El nombre es muy largo" } })}
                    type="text"
                    capitalize
                    placeholder="Nombre Completo"
                />
            </div>
            {
                country !== 'USA' && (
                    <>
                        <div className="send-form-interaction-comb">
                            {
                                country !== 'ARG' && (
                                    <div className="send-form-interaction-comb-child">
                                        <SelectGB
                                            {...register('doc_type')}
                                            options={docTypes[country]}
                                            placeholder="Tipo de Documento"
                                            style={{ type: "box", showPlaceHolderOnFocus: false }}
                                            optionsStyle={{ showPlaceholderOnList: true }}
                                        />
                                    </div>
                                )
                            }
                            <div className="send-form-interaction-comb-child">
                                <InputGB
                                    {...register('doc_num')}
                                    type="number"
                                    // integerStrict={true}
                                    placeholder={`Número de ${country === 'ARG' ? 'DNI' : docType.value !== '' ? docType.value : 'Documento'}`}
                                />
                            </div>
                        </div>
                        {
                            toOp !== 'NequiCOP' && toOp !== 'DaviplataCOP' && (
                                <>
                                    {
                                        toOp !== 'BancolombiaCOP' && (
                                            <div className="send-form-interaction">
                                                <SelectGB
                                                    {...register('account_bank')}
                                                    options={bankTypes[country]}
                                                    placeholder="Banco"
                                                    style={{ type: "box" }}
                                                />
                                            </div>
                                        )
                                    }
                                    <div className="send-form-interaction">
                                        <SelectGB
                                            {...register('account_type')}
                                            options={accTypes[country]}
                                            placeholder="Tipo de Cuenta"
                                            style={{ type: "box" }}
                                        />
                                    </div>
                                </>

                            )
                        }
                    </>
                )
            }
            {/* fijo pero el tipo de dato depende del acctype */}
            <div className="send-form-interaction">
                <InputGB
                    {...register('account_num')}
                    type="text"
                    // type={country === 'ARG' ? 'text' : country === 'USA' ? 'email' : 'number'}
                    placeholder={country !== 'USA' ? "Número de Cuenta" : "Correo Eléctronico"}
                />
            </div>

            <ButtonGB>Enviar</ButtonGB>
        </FormApp>
    )
}

export default DestForm;