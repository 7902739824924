import bancolombiaIMG from "../assets/images/bancolombia.png";
import bancosdeColombiaIMG from "../assets/images/BancoCO.png";
import daviplataIMG from "../assets/images/Daviplata.png";
import depositoArgentinaIMG from "../assets/images/BancoAR.png";
import nequiIMG from "../assets/images/Nequi.png";
import paypalIMG from "../assets/images/PayPal.png";
import transferenciaArgentinaIMG from "../assets/images/trans.png";

interface ImageOptionInt {
    option: string;
    size?: number;
}

const ImageOption = ({ option, size = 30 }: ImageOptionInt) => {

    return (
        <img
            alt="logo de opcion de cambio"
            src={option === "NequiCOP"
                ? nequiIMG
                : option === "DaviplataCOP"
                    ? daviplataIMG
                    : option === "BancolombiaCOP"
                        ? bancolombiaIMG
                        : option === "BancosdeColombiaCOP"
                            ? bancosdeColombiaIMG
                            : option === "TransferenciaArgentinaARS"
                                ? transferenciaArgentinaIMG
                                : option === "DepositoEfectivoARS"
                                    ? depositoArgentinaIMG
                                    : option === "PayPalUSD"
                                        ? paypalIMG
                                        : undefined
            }
            style={{
                width: `${size}px`
            }}
        />
    )
}

export default ImageOption;