import axios from "axios";
import useServices from "./services.service";
import { useLoading } from "../context/LoadingContext";
import { useAuth } from "../context/TokenContext";


const useLogOutService = () => {
    const { manageError, buildHeader } = useServices();
    const { setLoading } = useLoading();
    const { setToken } = useAuth();

    const logOut = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_LOGOUT || "", {}, { headers: buildHeader() })
            .then(() => setToken(undefined))
            .catch(error => manageError(error))
            .finally(() => setLoading(false))
    }

    return {
        logOut
    }
}

export default useLogOutService;