import axios from "axios";
import useServices from "./services.service";
import { useLoading } from "../context/LoadingContext";

const useLoginService = () => {
    const { manageError } = useServices();
    const { setLoading } = useLoading();

    const login = async (dataUser: any) => {
        try {
            setLoading(true);
            const { data } = await axios.post(process.env.REACT_APP_LOGIN || "", { ...dataUser, role: "clients" });
            return data.token
        } catch (error: any) {
            manageError(error);
            throw error.response.data.message || "";
        } finally {
            console.clear();
            setLoading(false);
        }
    }

    const forgotFirstCall = async (user: string) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_FORGOT_PASS_INIT || "", { user, role: 'clients' });
        } catch (error: any) {
            manageError(error);
            throw new error.response.data;
        } finally {
            setLoading(false);
        }
    }

    const forgotSecondCall = async (user: string, tempCode: number) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_FORGOT_PASS_VALIDATE || "", { user, tempCode });
        } catch (error: any) {
            manageError(error);
            throw error.response.data.message
        } finally {
            setLoading(false);
        }
    }

    const forgotThirdCall = async (user: string, newPassword: string) => {
        try {
            setLoading(true);
            await axios.post(process.env.REACT_APP_FORGOT_PASS_CHANGE || "", { user, newPassword });
            setLoading(false);
        } catch (error: any) {
            manageError(error);
            throw error.response.data.message;
        } finally {
            setLoading(false);
        }
    }

    return {
        login,
        forgotFirstCall,
        forgotSecondCall,
        forgotThirdCall
    }
}

export default useLoginService;